export enum SubscriptionType {
  FREE = "FREE",
  PLUS = "PLUS",
  PREMIUM = "PREMIUM",
}

export enum BadgeType {
  REGISTRATION_COMPLETED = "REGISTRATION_COMPLETED",
  FIRST_EXCHANGE_CONNECTION_CREATED = "FIRST_EXCHANGE_CONNECTION_CREATED",
  FIRST_DCA_BOT_CREATED = "FIRST_DCA_BOT_CREATED",
  FIRST_GRID_BOT_ORDER_FILLED = "FIRST_GRID_BOT_ORDER_FILLED",
  TWO_FACTOR_AUTH_COMPLETED = "TWO_FACTOR_AUTH_COMPLETED",
  FIRST_DCA_BOT_INSTANT_BUY_ORDER_FILLED = "FIRST_DCA_BOT_INSTANT_BUY_ORDER_FILLED",
  FIRST_DCA_BOT_INSTANT_SELL_ORDER_FILLED = "FIRST_DCA_BOT_INSTANT_SELL_ORDER_FILLED",
  FIRST_DCA_BOT_LIMIT_BUY_ORDER_FILLED = "FIRST_DCA_BOT_LIMIT_BUY_ORDER_FILLED",
  SET_DCA_BOT_INITIAL_AMOUNTS = "SET_DCA_BOT_INITIAL_AMOUNTS",
  SET_DCA_BOT_TARGET_VALUE_PRICE = "SET_DCA_BOT_TARGET_VALUE_PRICE",
  WITHDRAWAL_CONNECTION_CREATED = "WITHDRAWAL_CONNECTION_CREATED",
  CREDITS_PACKAGE_PURCHASED = "CREDITS_PACKAGE_PURCHASED",
  REFERRED_3_USERS = "REFERRED_3_USERS",
  REFERRED_10_USERS = "REFERRED_10_USERS",
}

export enum BadgeState {
  UNCLAIMED = "UNCLAIMED",
  CLAIMED = "CLAIMED",
}

export enum CreditsTransactionType {
  BADGE_CLAIMED = "BADGE_CLAIMED",
  CREDITS_BOUGHT = "CREDITS_BOUGHT",
  CREDITS_RECHARGED = "CREDITS_RECHARGED",
  SUBSCRIPTION_PURCHASED = "SUBSCRIPTION_PURCHASED",
  SUBSCRIPTION_REFUNDED = "SUBSCRIPTION_REFUNDED",
  CREDITS_VOUCHER = "CREDITS_VOUCHER",
  AFF_BONUS = "AFF_BONUS",
  REFERRAL_BONUS = "REFERRAL_BONUS",
  OTHER = "OTHER",
  CREDITS_VOUCHERS_BOUGHT = "CREDITS_VOUCHERS_BOUGHT",
}

export enum CreditsPaymentType {
  CREDITS_300_V2 = "CREDITS_300_V2",
  CREDITS_600_V2 = "CREDITS_600_V2",
  CREDITS_1800_V2 = "CREDITS_1800_V2",
  CREDITS_3600_V2 = "CREDITS_3600_V2",
  CREDITS_HALF_3600 = "CREDITS_HALF_3600",
  CREDITS_HALF_10000 = "CREDITS_HALF_10000",
  CREDITS_THIRD_3600 = "CREDITS_THIRD_3600",
}

export enum CreditsPaymentGateway {
  OPEN_NODE = "OPEN_NODE",
  THE_PAY = "THE_PAY",
  STRIPE = "STRIPE",
}

export enum BotStrategyType {
  LIMIT = "LIMIT",
  MARKET = "MARKET",
}

export enum OrderType {
  LIMIT = "LIMIT",
  MARKET = "MARKET",
}

export enum OrderTriggering {
  INSTANT = "INSTANT",
  PERIODIC = "PERIODIC",
  MANUAL = "MANUAL",
  EXIT = "EXIT",
}

export enum ExchangeType {
  COINMATE = "COINMATE",
  ANYCOIN = "ANYCOIN",
  COINBASEPRO = "COINBASEPRO",
  BINANCE = "BINANCE",
  BITSTAMP = "BITSTAMP",
}

export enum DcaBotRunState {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  OPEN = "OPEN",
  CLOSING = "CLOSING",
  DONE = "DONE",
  ERROR = "ERROR",
  MANUAL_DONE = "MANUAL_DONE",
}

export enum Side {
  BUY = "BUY",
  SELL = "SELL",
}

export enum DcaBotOrderStatus {
  PENDING_NEW = "PENDING_NEW",
  OPEN = "OPEN",
  FILLED = "FILLED",
  PARTIALLY_FILLED = "PARTIALLY_FILLED",
  PARTIALLY_CANCELED = "PARTIALLY_CANCELED",
  PENDING_CANCEL = "PENDING_CANCEL",
  CANCELED = "CANCELED",
  ERROR_CALCULATING = "ERROR_CALCULATING",
  ERROR_SMALL_BALANCE = "ERROR_SMALL_BALANCE",
  ERROR_PLACING = "ERROR_PLACING",
  ERROR_CANCELING = "ERROR_CANCELING",
}

export enum GridPriceLevelStatus {
  PENDING_NEW = "PENDING_NEW",
  OPEN = "OPEN",
  PARTIALLY_FILLED = "PARTIALLY_FILLED",
  PENDING_CANCEL = "PENDING_CANCEL",
  PENDING_FUNDS = "PENDING_FUNDS",
  NOT_ENOUGH_BALANCE = "NOT_ENOUGH_BALANCE",
  ENOUGH_BALANCE = "ENOUGH_BALANCE",
  GAP = "GAP",
}

export enum WithdrawalState {
  STARTED = "STARTED",
  DONE = "DONE",
  ERROR = "ERROR",
}

export enum OHLCInterval {
  ONE_DAY = "ONE_DAY",
  ONE_HOUR = "ONE_HOUR",
  ONE_MINUTE = "ONE_MINUTE",
}

export enum RechargeType {
  RECHARGE_125 = "RECHARGE_125",
  RECHARGE_150 = "RECHARGE_150",
  RECHARGE_250 = "RECHARGE_250",
  RECHARGE_500 = "RECHARGE_500",
}

export enum RechargeState {
  INACTIVE = "INACTIVE", //only on FE
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  CANCELED = "CANCELED",
}

export enum DcaBuyStrategyType {
  FIXED = "FIXED",
  DYNAMIC = "DYNAMIC",
}

export enum DcaDynamicStrategyType {
  EMPTY = "---",
  BTC_NUPL = "BTC_NUPL",
  BTC_FEAR_AND_GREED = "BTC_FEAR_AND_GREED",
}

export enum LayoutPosition {
  TOP = "TOP",
  BOTTOM = "BOTTOM",
}

export enum DcaBotMode {
  DCA_BUY = "DCA_BUY",
  DCA_SELL = "DCA_SELL",
  COMBO = "COMBO",
  MANUAL = "MANUAL",
}

export enum CashbackSatsType {
  DCA = "d",
  GRID = "g",
  AFFILIATE = "a",
}

export enum ExitStrategyType {
  LINEAR_BASE_CURR_SELL = "LINEAR_BASE_CURR_SELL",
  PCT_OF_GROWTH_SELL = "PCT_OF_GROWTH_SELL",
}

export enum BuyBackModeType {
  NONE = "NONE",
  ONCE = "ONCE",
  REPEAT = "REPEAT",
}

export enum SellOrderStatus {
  NOT_ENOUGH_BALANCE = "NOT_ENOUGH_BALANCE",
  ENOUGH_BALANCE = "ENOUGH_BALANCE",
  APPROVED = "APPROVED",
}

export enum ExitSellOrderStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  EXECUTING = "EXECUTING",
  EXECUTED = "EXECUTED",
  FAILED = "FAILED",
}

export enum OrderCategory {
  INITIAL_AMOUNT = "INITIAL_AMOUNT",
  PERIODIC_MARKET = "PERIODIC_MARKET",
  PERIODIC_LIMIT = "PERIODIC_LIMIT",
  INSTANT_MARKET = "INSTANT_MARKET",
  INSTANT_LIMIT = "INSTANT_LIMIT",
  MANUAL = "MANUAL",
  EXIT = "EXIT",
}
