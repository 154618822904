import React from "react";

import AddchartIcon from "@mui/icons-material/Addchart";
import CreateIcon from "@mui/icons-material/Create";
import { Box, Checkbox, FormControlLabel, Stack, ToggleButton, Typography } from "@mui/material";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { FilterList, CheckIcon, CheckIconBorder , CoinsStacked, CoinsSwap2 } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useTranslation } from "react-i18next";
import { OrderTriggering, Side } from "types/enums";

interface FilterProps {
  disabled?: boolean;
  onChange: (filter: IFilter) => void;
  value: IFilter;
  countEmpty?: number;
}

export interface IFilter {
  side: Side[];
  orderTriggering: OrderTriggering[];
  empty?: "HIDE" | "SHOW";
}

const Header = styled(Box)(({ theme }) => {
  return {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    fontWeight: 500,
    color: "#6b7280",
  };
});

const CheckboxControlLabel = styled(FormControlLabel)(({ theme }) => {
  return {
    marginRight: 0,
    "& .MuiTypography-root": {
      fontSize: "0.8125rem",
      fontWeight: 500,
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  };
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => {
  const { colors } = useMUIThemeModeContext();
  return {
    "& .MuiPaper-root": {
      borderRadius: 10,
      marginTop: theme.spacing(1),
      minWidth: 300,
      border: `1px solid ${alpha(colors.black, 0.1)}`,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  };
});

const Filter: React.FC<FilterProps> = ({ disabled, value, onChange, countEmpty = 0 }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const hasActiveAnyFilter = () => {
    return value.side.length > 0 || value.orderTriggering.length > 0 || Boolean(value.empty);
  };

  const handleSideChange = (side: Side) => {
    const newFilters = { ...value };

    if (newFilters.side.includes(side)) {
      newFilters.side = newFilters.side.filter((s) => s !== side);
    } else {
      newFilters.side.push(side);
    }

    onChange(newFilters);
  };

  const handleOrderTriggeringChange = (orderTriggering: OrderTriggering) => {
    const newFilters = { ...value };

    if (newFilters.orderTriggering.includes(orderTriggering)) {
      newFilters.orderTriggering = newFilters.orderTriggering.filter((ot) => ot !== orderTriggering);
    } else {
      newFilters.orderTriggering.push(orderTriggering);
    }

    onChange(newFilters);
  };

  const handleEmptyChange = (empty: "HIDE" | "SHOW") => {
    const newFilters = { ...value };
    if (newFilters.empty === empty) {
      delete newFilters.empty;
    } else {
      newFilters.empty = empty;
    }
    onChange(newFilters);
  };

  return (
    <>
      <ToggleButton
        disabled={disabled}
        color="primary"
        value="filter"
        size="small"
        selected={hasActiveAnyFilter()}
        onChange={handleClick}>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <FilterList />
          <p>{t("dcaBots.runsList.filters.title")}</p>
        </Stack>
      </ToggleButton>
      <StyledMenu id="filter" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Header>
          <Typography fontSize={"0.75rem"}>{t("dcaBots.runsList.filters.typeOfTrade")}</Typography>
        </Header>
        <Stack px={1.5}>
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleSideChange(Side.BUY)}
                checked={value.side.includes(Side.BUY)}
              />
            }
            label={t("dcaBots.runsList.filters.buy")}
          />
          <CheckboxControlLabel
            value={Side.SELL}
            checked={value.side.includes(Side.SELL)}
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleSideChange(Side.SELL)}
                checked={value.side.includes(Side.SELL)}
              />
            }
            label={t("dcaBots.runsList.filters.sell")}
          />
        </Stack>
        <Header>
          <Typography fontSize={"0.75rem"}>{t("dcaBots.runsList.filters.typeOfOrder")}</Typography>
        </Header>
        <Stack px={1.5}>
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleOrderTriggeringChange(OrderTriggering.PERIODIC)}
                checked={value.orderTriggering.includes(OrderTriggering.PERIODIC)}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                {t("dcaBots.runsList.filters.periodic")}
                <CoinsStacked color="inherit"/>
              </Stack>
            }
          />
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleOrderTriggeringChange(OrderTriggering.INSTANT)}
                checked={value.orderTriggering.includes(OrderTriggering.INSTANT)}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                {t("dcaBots.runsList.filters.instant")}
                <AddchartIcon color="inherit" />
              </Stack>
            }
          />
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleOrderTriggeringChange(OrderTriggering.MANUAL)}
                checked={value.orderTriggering.includes(OrderTriggering.MANUAL)}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                {t("dcaBots.runsList.filters.manual")}
                <CreateIcon color="inherit" />
              </Stack>
            }
          />
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleOrderTriggeringChange(OrderTriggering.EXIT)}
                checked={value.orderTriggering.includes(OrderTriggering.EXIT)}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                {t("dcaBots.runsList.filters.exit")}
                <CoinsSwap2 color="inherit" />
              </Stack>
            }
          />
        </Stack>
        <Header>
          <Typography fontSize={"0.75rem"}>{t("dcaBots.runsList.filters.errors")}</Typography>
        </Header>
        <Stack px={1.5}>
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleEmptyChange("HIDE")}
                checked={value.empty === "HIDE"}
                disabled={countEmpty === 0}
              />
            }
            label={t("dcaBots.runsList.filters.hide", { count: countEmpty })}
          />
          <CheckboxControlLabel
            control={
              <Checkbox
                icon={<CheckIconBorder />}
                checkedIcon={<CheckIcon />}
                onChange={() => handleEmptyChange("SHOW")}
                checked={value.empty === "SHOW"}
                disabled={countEmpty === 0}
              />
            }
            label={t("dcaBots.runsList.filters.show", { count: countEmpty })}
          />
        </Stack>
      </StyledMenu>
    </>
  );
};

export default Filter;
