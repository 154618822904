import { useMemo } from "react";

import { useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { IPreviewExitStrategy } from "types/types";
import { splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

interface Props {
  previewData?: IPreviewExitStrategy;
  currencyPair: string;
  exchangeEnum: string;
}

const OverviewChart = ({ previewData, currencyPair, exchangeEnum }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  const { showInUSD } = useUserDataContext();
  const { getUSDOriginalPrice, hasRateForCurrency } = useExchangeRate();
  const { currencyPairPriceScale, counterCurrDisplayedScale } = useExchangesMetadata(exchangeEnum, currencyPair);
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);

  const getValue = (value: number) => {
    if (showInUSD) {
      const inUSD = getUSDOriginalPrice(counterCurrency, value);
      return inUSD ?? value;
    }

    return value;
  };

  const labels = useMemo(() => {
    const currency = showInUSD ? "USD" : counterCurrency;
    return (
      previewData?.sellOrders.map(
        (item) => `${renderNumber(getValue(item.price), currencyPairPriceScale, true)} ${currency}`
      ) ?? []
    );
  }, [previewData, showInUSD, hasRateForCurrency, counterCurrency]);

  const series = useMemo(
    () => [
      {
        name: t("exitStrategy.create.overviewChart.valueSum"),
        data: previewData?.sellOrders.map((item) => item.valueSum) ?? [],
      },
      {
        name: t("exitStrategy.create.overviewChart.remainingValue", { baseCurrency }),
        data: previewData?.sellOrders.map((item) => item.remainingValue) ?? [],
      },
      {
        name: t("exitStrategy.create.overviewChart.total", { baseCurrency }),
        data: previewData?.sellOrders.map((item) => item.valueSum + item.remainingValue) ?? [],
      },
    ],
    [previewData, baseCurrency]
  );

  // chart config
  const options: ApexOptions = {
    theme: { mode: theme.palette.mode },
    tooltip: {
      followCursor: true,
      inverseOrder: true,
      y: {
        formatter: (val: number) => `${renderNumber(val, counterCurrDisplayedScale, true)} ${counterCurrency}`,
      },
    },
    colors: [theme.palette.success.main, theme.palette.primary.main, theme.palette.tertiary.main],
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: ["straight", "straight", "straight"],
      width: [3, 3, 3],
    },
    grid: {
      row: {
        colors: [colors.legacyGray100, "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        hideOverlappingLabels: true,
        rotate: -30,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val: any) => `${renderNumber(val, counterCurrDisplayedScale, true)} ${counterCurrency}`,
      },
      min: 0,
    },
  };

  return <ReactApexChart options={options} series={series} type="line" width={"100%"} height={400} />;
};

export default OverviewChart;
