import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import CenterWrapper from "components/elements/CenterWrapper";
import PageTitle from "components/elements/PageTitle";
import ExitStrategy from "components/modules/ExitStrategy";
import Loading from "components/modules/Loading";
import { useAPIDcaBotsDetail } from "hooks/useAPI";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import { useNavigate, useParams } from "react-router-dom";
import { ExitStrategyType } from "types/enums";

const ExitStrategyPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [canRender, setCanRender] = useState<boolean>(false);
  const [enabledExitStrategies, setEnabledExitStrategies] = useState<ExitStrategyType[]>([]);

  const { data: currencyPairs } = useCurrencyPairs();
  const { data, mutate } = useAPIDcaBotsDetail(id);

  useEffect(() => {
    if (data && currencyPairs.length) {
      if (data.exitStrategy) navigate(-1);

      const exchangeEnum = data.exchangeConnection.exchangeEnum;
      const currencyPair = data.currencyPair;

      const exchangeMetadata = currencyPairs.find((item) => item.id === exchangeEnum);
      const currencyPairMetadata = exchangeMetadata.data.find((item: any) => item.key === currencyPair);

      if (currencyPairMetadata?.enabled_exit_strategies?.length) {
        setEnabledExitStrategies(currencyPairMetadata.enabled_exit_strategies);
        setCanRender(true);
      } else navigate(-1);
    }
  }, [data, currencyPairs]);

  if (!id || !canRender || !data || !data.details) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  }

  return (
    <Box key={id} sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <PageTitle i18nKey="exitStrategy.meta_title" />
      <ExitStrategy id={id} data={data} enabledExitStrategies={enabledExitStrategies} refreshData={() => mutate()} />
    </Box>
  );
};

export default ExitStrategyPage;
