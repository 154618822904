import { SvgIcon } from "@mui/material";

export const Scales = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.70312 17V16.8498C2.70312 16.5333 2.70312 16.3751 2.72733 16.2209C2.74883 16.084 2.78449 15.9497 2.83374 15.8202C2.88921 15.6743 2.96772 15.5369 3.12475 15.2622L6.70312 9M2.70312 17C2.70312 19.2091 4.49399 21 6.70312 21C8.91226 21 10.7031 19.2091 10.7031 17M2.70312 17V16.8C2.70312 16.52 2.70312 16.38 2.75762 16.273C2.80556 16.1789 2.88205 16.1024 2.97613 16.0545C3.08309 16 3.2231 16 3.50313 16H9.90312C10.1832 16 10.3232 16 10.4301 16.0545C10.5242 16.1024 10.6007 16.1789 10.6486 16.273C10.7031 16.38 10.7031 16.52 10.7031 16.8V17M6.70312 9L10.2815 15.2622C10.4385 15.5369 10.517 15.6743 10.5725 15.8202C10.6218 15.9497 10.6574 16.084 10.6789 16.2209C10.7031 16.3751 10.7031 16.5333 10.7031 16.8498V17M6.70312 9L18.7031 7M14.7031 15V14.8498C14.7031 14.5333 14.7031 14.3751 14.7273 14.2209C14.7488 14.084 14.7845 13.9497 14.8337 13.8202C14.8892 13.6743 14.9677 13.5369 15.1247 13.2622L18.7031 7M14.7031 15C14.7031 17.2091 16.494 19 18.7031 19C20.9123 19 22.7031 17.2091 22.7031 15M14.7031 15V14.8C14.7031 14.52 14.7031 14.38 14.7576 14.273C14.8056 14.1789 14.882 14.1024 14.9761 14.0545C15.0831 14 15.2231 14 15.5031 14H21.9031C22.1832 14 22.3232 14 22.4301 14.0545C22.5242 14.1024 22.6007 14.1789 22.6486 14.273C22.7031 14.38 22.7031 14.52 22.7031 14.8V15M18.7031 7L22.2815 13.2622C22.4385 13.5369 22.517 13.6743 22.5725 13.8202C22.6218 13.9497 22.6574 14.084 22.6789 14.2209C22.7031 14.3751 22.7031 14.5333 22.7031 14.8498V15M12.7031 3V8"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
