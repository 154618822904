export const QuestionMark2 = () => {
  return (
    <svg width="5" height="7" viewBox="0 0 5 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.44556 0.49646C3.04509 0.49646 3.52412 0.661036 3.88266 0.990188C4.24708 1.31934 4.42929 1.76898 4.42929 2.33912C4.42929 2.93277 4.2412 3.37948 3.86503 3.67924C3.48885 3.979 2.98925 4.12889 2.36621 4.12889L2.33094 4.82539H1.4581L1.41402 3.44119H1.70497C2.27511 3.44119 2.71006 3.36478 3.00982 3.21196C3.31546 3.05914 3.46828 2.7682 3.46828 2.33912C3.46828 2.0276 3.37718 1.78368 3.19497 1.60735C3.01864 1.43102 2.77177 1.34285 2.45438 1.34285C2.13698 1.34285 1.88718 1.42808 1.70497 1.59853C1.52276 1.76898 1.43165 2.00703 1.43165 2.31267H0.488281C0.488281 1.96001 0.56763 1.64555 0.726329 1.3693C0.885027 1.09305 1.11132 0.878511 1.4052 0.725691C1.70497 0.57287 2.05175 0.49646 2.44556 0.49646ZM1.8813 6.77386C1.69909 6.77386 1.54627 6.71214 1.42284 6.58871C1.29941 6.46528 1.23769 6.31246 1.23769 6.13025C1.23769 5.94804 1.29941 5.79522 1.42284 5.67178C1.54627 5.54835 1.69909 5.48664 1.8813 5.48664C2.05763 5.48664 2.20751 5.54835 2.33094 5.67178C2.45438 5.79522 2.51609 5.94804 2.51609 6.13025C2.51609 6.31246 2.45438 6.46528 2.33094 6.58871C2.20751 6.71214 2.05763 6.77386 1.8813 6.77386Z"
        fill="currentColor"
      />
    </svg>
  );
};
