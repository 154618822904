import { useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { alpha, Button, Stack, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { Buy, Sell } from "components/icons";
import { TableHeaderWithTooltip } from "components/modules/ExitStrategy/Preview";
import { fontWeight } from "config/Theme/options";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { IExitBuyOrder, IExitSellOrder } from "types/types";
import { percentageChange, splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

interface IProps {
  data: IExitSellOrder[] | IExitBuyOrder[];
  currencyPair: string;
  exchangeEnum: string;
  type: "sell" | "buy";
  rate?: number;
}

const PercentageBadge = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    color: colors["gray500"],
    backgroundColor: alpha(colors["gray450"], 0.08),
    paddingTop: theme.spacing(0.125),
    paddingBottom: theme.spacing(0.125),
    paddingLeft: theme.spacing(0.375),
    paddingRight: theme.spacing(0.375),
    fontWeight: 500,
    fontSize: "0.625rem",
    fontFamily: "monospace",
    display: "flex",
    borderRadius: 999,
  };
});

const DEFAULT_PAGE_SIZE = 3;

const Table = ({ data, currencyPair, exchangeEnum, type, rate }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { showInUSD } = useUserDataContext();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ pageSize: DEFAULT_PAGE_SIZE, page: 0 });

  const { getUSDOriginalPrice, hasRateForCurrency } = useExchangeRate();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, currencyPairBaseScale } = useExchangesMetadata(exchangeEnum, currencyPair);

  const getPriceCurrencyPair = () => {
    if (showInUSD && hasRateForCurrency(counterCurrency)) return `${baseCurrency}/USD`;

    return currencyPair;
  };

  const getValue = (value: number) => {
    if (showInUSD) {
      const inUSD = getUSDOriginalPrice(counterCurrency, value);
      return inUSD ?? value;
    }

    return value;
  };

  const getPercentageDistance = (value: number) => {
    if (!rate) return null;

    const percentage = percentageChange(rate, value);
    const percentageNumber = renderNumber(percentage, 1, true);

    return `${percentage > 0 ? "+" : ""}${percentageNumber}\u00a0%`;
  };

  const columns = useMemo<GridColDef<IExitSellOrder | IExitBuyOrder>[]>(
    () => [
      {
        field: "price",
        headerName: `dcaBots.exitStrategy.table.${type}.price`,
        renderHeader: TableHeaderWithTooltip(
          { currencyPair: getPriceCurrencyPair() },
          `${t(`dcaBots.exitStrategy.table.${type}.priceTooltip`)}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 155,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => {
          const percentage = getPercentageDistance(value);

          return (
            <Stack spacing={1} direction={"row"} alignItems={"center"}>
              {type === "sell" ? <Sell sx={{ color: "#D92D20", fontSize: "1rem" }} /> : <Buy sx={{ fontSize: "1rem" }} />}
              <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} paddingRight={0.25}>
                {renderNumber(getValue(value), counterCurrDisplayedScale, true)}
              </Typography>
              {percentage && <PercentageBadge>{percentage}</PercentageBadge>}
            </Stack>
          );
        },
      },
      {
        field: "amount",
        headerName: `dcaBots.exitStrategy.table.${type}.amount`,
        renderHeader: TableHeaderWithTooltip(
          { baseCurrency },
          `${t(`dcaBots.exitStrategy.table.${type}.amountTooltip`)}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => {
          const color = type === "sell" ? colors["error"] : colors["success"];
          const prefix = type === "sell" ? "-" : "+";

          return (
            <MaskedModeWrapper fontSize={"inherit"} fontWeight={fontWeight.semiBold}>
              <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={color}>
                {prefix}
                {renderNumber(value, currencyPairBaseScale, true)}
              </Typography>
            </MaskedModeWrapper>
          );
        },
      },
      {
        field: "value",
        headerName: `dcaBots.exitStrategy.table.${type}.value`,
        renderHeader: TableHeaderWithTooltip(
          { counterCurrency },
          `${t(`dcaBots.exitStrategy.table.${type}.valueTooltip`)}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => {
          const color = type === "sell" ? colors["success"] : colors["error"];
          const prefix = type === "sell" ? "+" : "-";

          return (
            <MaskedModeWrapper fontWeight={fontWeight.semiBold} fontSize={"inherit"}>
              <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={color}>
                {prefix}
                {renderNumber(value, counterCurrDisplayedScale, true)}
              </Typography>
            </MaskedModeWrapper>
          );
        },
      },
      {
        field: "status",
        headerName: `dcaBots.exitStrategy.table.${type}.status`,
        renderHeader: TableHeaderWithTooltip({}, `${t(`dcaBots.exitStrategy.table.${type}.statusTooltip`)}`),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        valueFormatter: ({ value }: GridValueFormatterParams<any>) => {
          if (type === "sell") {
            return t(`dcaBots.exitStrategy.table.status.${value}`);
          }

          return t(`dcaBots.exitStrategy.table.status.ACTIVE`);
        },
      },
    ],
    [baseCurrency, counterCurrency, currencyPairBaseScale, counterCurrDisplayedScale, showInUSD, rate, hasRateForCurrency]
  );

  const getShowMoreButton = () => {
    const sum = data.length - DEFAULT_PAGE_SIZE;

    if (paginationModel.pageSize === data.length) {
      return (
        <Button
          endIcon={<KeyboardArrowUpIcon />}
          onClick={() => setPaginationModel({ pageSize: DEFAULT_PAGE_SIZE, page: 0 })}>
          {t(`dcaBots.exitStrategy.table.${type}.hide`, { sum })}
        </Button>
      );
    }

    return (
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => setPaginationModel({ pageSize: data.length, page: 0 })}>
        {t(`dcaBots.exitStrategy.table.${type}.showMore`, { sum })}
      </Button>
    );
  };

  return (
    <Box pt={type === "buy" ? 4 : 0}>
      <Typography sx={{ pb: 3 }} fontWeight={fontWeight.semiBold} fontSize={"1.25rem"}>
        {t(`dcaBots.exitStrategy.table.${type}.title`)}
      </Typography>
      <DataGrid
        rows={data}
        autoHeight
        columns={columns}
        getRowId={(row: IExitSellOrder | IExitBuyOrder) => row.price}
        hideFooter
        disableRowSelectionOnClick
        paginationModel={paginationModel}
        sx={{
          border: "none",
          borderBottom: `1px solid ${colors["tableBorder"]}`,
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: `1px solid ${colors["tableBorder"]}`,
            color: colors["gray500"],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${colors["tableBorder"]}`,
            fontWeight: 500,
          },
        }}
      />
      {data.length - DEFAULT_PAGE_SIZE > 0 ? (
        <Box display={"flex"} justifyContent={"center"} mt={2}>
          {getShowMoreButton()}
        </Box>
      ) : null}
    </Box>
  );
};

export default Table;
