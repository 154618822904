import React from "react";

import {alpha, Box, Stack, Typography} from "@mui/material";
import Badge from "components/elements/Badge";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import UsdWrapper, {TypographyItem} from "components/elements/UsdWrapper";
import SatsView from "components/modules/SatsView";
import {useMUIThemeModeContext} from "context/MUIThemeModeContext";
import {useTranslation} from "react-i18next";
import {IRunsSummaryStats} from "types/types";
import {renderNumber} from "utils/formatter";

interface IStatsCardProps {
  type: "buy" | "sell";
  stats: IRunsSummaryStats;
  baseCurrency?: string;
  counterCurrency?: string;
  baseScale: number;
  counterScale: number;
  currencyPairPriceScale: number;
}

const StatsCard: React.FC<IStatsCardProps> = ({
  type,
  stats,
  counterScale,
  counterCurrency,
  baseScale,
  baseCurrency,
  currencyPairPriceScale,
}) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const pricePreffix = type === "buy" ? "-" : "+";
  const amountPreffix = type === "buy" ? "+" : "-";

  const getCategory = (key: string, count: number, value: number) => {
    if (!value) return null;

    return (
      <Stack spacing={0.75}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography fontSize={"0.875rem"} fontWeight={500}>
            {t(`dcaBots.runsList.summary.stats.${type}.category.${key}`)}
          </Typography>
          <Badge color={type === "buy" ? "success" : "error"}>
            <Typography sx={{ py: 0.2 }} fontSize={"0.75rem"}>{`${renderNumber(count)}×`}</Typography>
          </Badge>
        </Stack>
        <MaskedModeWrapper fontSize={"0.875rem"} color={colors["gray500"]} fontWeight={500}>
          <SatsView
            color={colors["gray500"]}
            fontWeight={500}
            fontSize={"0.875rem"}
            value={value}
            currency={counterCurrency ?? ""}
            scale={counterScale}
          />
        </MaskedModeWrapper>
      </Stack>
    );
  };

  return (
    <Box p={3} borderRadius={"10px"} border={`1px solid ${alpha(colors["gray450"], 0.1)}`} maxWidth={360}>
      <Stack spacing={2}>
        <Box>
          <Typography sx={{ pb: 1.5 }} fontSize={"0.875rem"} color={colors["gray500"]}>
            {t(`dcaBots.runsList.summary.stats.${type}.title`)}
          </Typography>
          <MaskedModeWrapper fontSize={"1.5rem"} fontWeight={500}>
            <SatsView
              color={colors.black}
              fontWeight={500}
              fontSize={"1.5rem"}
              value={stats.ordersValue}
              currency={counterCurrency ?? ""}
              scale={counterScale}
              prefix={pricePreffix}
            />
          </MaskedModeWrapper>
          <MaskedModeWrapper
            fontSize={"0.875rem"}
            color={colors[type === "buy" ? "success" : "error"]}
            fontWeight={500}>
            <SatsView
              color={colors[type === "buy" ? "success" : "error"]}
              fontWeight={500}
              fontSize={"0.875rem"}
              value={stats.ordersAmount}
              currency={baseCurrency ?? ""}
              scale={baseScale}
              prefix={amountPreffix}
            />
          </MaskedModeWrapper>
        </Box>
        <Box border={`1px solid ${alpha(colors["gray450"], 0.1)}`} />
        <Box>
          <Typography fontSize={"0.75rem"} color={colors["gray500"]}>
            {t(`dcaBots.runsList.summary.stats.${type}.averagePrice`)}
          </Typography>
          <UsdWrapper value={stats.averagePrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
            <TypographyItem
              fontSize={"0.875rem"}
              fontWeight={500}
              value={stats.averagePrice}
              scale={currencyPairPriceScale}
              currency={counterCurrency}
              withZeros
            />
          </UsdWrapper>
        </Box>
        <Box border={`1px solid ${alpha(colors["gray450"], 0.1)}`} />
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography fontSize={"0.75rem"} color={colors["gray500"]}>
            {t(`dcaBots.runsList.summary.stats.${type}.ordersCount`)}
          </Typography>
          <Badge color={type === "buy" ? "success" : "error"}>
            <Typography sx={{ py: 0.2 }} fontSize={"0.75rem"} fontWeight={700}>{`${renderNumber(stats.ordersCount)}×`}</Typography>
          </Badge>
        </Stack>
        <Box border={`1px solid ${alpha(colors["gray450"], 0.1)}`} />
        <Stack spacing={1.5}>
          {stats.orderCategories.INITIAL_AMOUNT
            ? getCategory(
                "INITIAL_AMOUNT",
                stats.orderCategories.INITIAL_AMOUNT.count,
                stats.orderCategories.INITIAL_AMOUNT.value
              )
            : null}
          {stats.orderCategories.PERIODIC_MARKET
            ? getCategory(
                "PERIODIC_MARKET",
                stats.orderCategories.PERIODIC_MARKET.count,
                stats.orderCategories.PERIODIC_MARKET.value
              )
            : null}
          {stats.orderCategories.PERIODIC_LIMIT
            ? getCategory(
                "PERIODIC_LIMIT",
                stats.orderCategories.PERIODIC_LIMIT.count,
                stats.orderCategories.PERIODIC_LIMIT.value
              )
            : null}
          {stats.orderCategories.INSTANT_MARKET
            ? getCategory(
                "INSTANT_MARKET",
                stats.orderCategories.INSTANT_MARKET.count,
                stats.orderCategories.INSTANT_MARKET.value
              )
            : null}
          {stats.orderCategories.INSTANT_LIMIT
            ? getCategory(
                "INSTANT_LIMIT",
                stats.orderCategories.INSTANT_LIMIT.count,
                stats.orderCategories.INSTANT_LIMIT.value
              )
            : null}
          {stats.orderCategories.MANUAL
            ? getCategory("MANUAL", stats.orderCategories.MANUAL.count, stats.orderCategories.MANUAL.value)
            : null}
          {stats.orderCategories.EXIT
            ? getCategory("EXIT", stats.orderCategories.EXIT.count, stats.orderCategories.EXIT.value)
            : null}
        </Stack>
      </Stack>
    </Box>
  );
};

export default StatsCard;
