import { SvgIcon } from "@mui/material";

export const ArrangeSquare2 = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.13477 22H15.1348C20.1348 22 22.1348 20 22.1348 15V9C22.1348 4 20.1348 2 15.1348 2H9.13477C4.13477 2 2.13477 4 2.13477 9V15C2.13477 20 4.13477 22 9.13477 22Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3144 17.15L7.27441 14.11"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.3145 6.84998V17.15"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9551 6.84998L16.9951 9.88998"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.9551 17.15V6.84998"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
