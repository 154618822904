import React from "react";

import { Typography, TypographyProps, alpha } from "@mui/material";
import { renderNumber } from "utils/formatter";

export interface CurrencyViewProps extends TypographyProps{
  color: string;
  value: number;
  currency: string;
  scale?: number;
  withZeros?: boolean;
  prefix?: string;
}

const CurrencyView: React.FC<CurrencyViewProps> = ({
  color,
  value,
  currency,
  scale = 2,
  withZeros = false,
  prefix,
  ...props
}) => {
  const arrayOfNumbers = renderNumber(value, scale, withZeros).split("");
  const numberIndex = arrayOfNumbers.findIndex((num) => Number(num) > 0);

  return (
    <Typography {...props}>
      {prefix && <span style={{ color: color }}>{prefix}</span>}
      {arrayOfNumbers.map((digit, index) => {
        const digitColor = numberIndex <= index ? color : alpha(color, 0.4);

        return (
          <span key={index} style={{ color: digitColor }}>
            {digit}
          </span>
        );
      })}
      <span style={{ color: color }}>{`\u00a0${currency}`}</span>
    </Typography>
  );
};

export default CurrencyView;
