import { alpha, Box, Grid, Stack, Typography, styled, AlertTitle, Alert } from "@mui/material";
import Big from "big.js";
import CenterWrapper from "components/elements/CenterWrapper";
import { Diamond, Sell } from "components/icons";
import Loading from "components/modules/Loading";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormikContext } from "formik";
import { useAPICurrencyPairsTicker } from "hooks/useAPI";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { ExitStrategyType, SellOrderStatus, SubscriptionType } from "types/enums";
import { IDetails, IPreviewExitStrategy } from "types/types";
import { increaseByPercentage, splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

import { ExitStrategyFormikValues } from "../module/useExitStrategyData";
import Chart from "./Chart";
import InputWithLog from "./InputWithLog";
import InputWithSlider from "./InputWithSlider";
import OverviewChart from "./OverviewChart";
import StepInput from "./StepInput";

interface IProps {
  details: IDetails;
  currencyPair: string;
  exchangeEnum: string;
  exchangeTitle: string;
  previewData?: IPreviewExitStrategy;
  isBrokerage?: boolean;
}

const leftGridItemProps = {
  xs: 12,
  lg: 8,
  pr: { xs: 0, lg: 4 },
};

const rightGridItemProps = {
  xs: 12,
  lg: 4,
  pl: { xs: 0, lg: 4 },
  pt: { xs: 4, lg: 0 },
};

const smallerLeftGridItemProps = {
  xs: 12,
  lg: 4,
  pr: { xs: 0, lg: 4 },
};

const largerRightGridItemProps = {
  xs: 12,
  lg: 8,
  pl: { xs: 0, lg: 4 },
  pt: { xs: 4, lg: 0 },
};

const sellingPriceStepPctMin = {
  [SubscriptionType.FREE]: 3,
  [SubscriptionType.PLUS]: 2,
  [SubscriptionType.PREMIUM]: 1,
};

const RedBadge = styled(Box)(({ theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    borderRadius: "10px",
    color: colors["error"],
    backgroundColor: alpha(colors["error"], 0.08),
    padding: theme.spacing(0.75),
  };
});

const Settings = ({ details, currencyPair, exchangeEnum, exchangeTitle, previewData, isBrokerage }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const formik = useFormikContext<ExitStrategyFormikValues>();
  const { subscriptionType } = useUserDataContext();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { baseCurrScale, currencyPairPriceScale, currencyPairBaseScale } = useExchangesMetadata(exchangeEnum, currencyPair);
  const { data } = useAPICurrencyPairsTicker(`${baseCurrency}-${counterCurrency}`, exchangeEnum);
  const { getUSDPrice, hasRateForCurrency } = useExchangeRate();
  const type = formik.values.type;

  const getApproxUsdPrice = (value: string | number, currency: string) => {
    const price = getUSDPrice(currency, value, 0);
    return price ? `~ ${price}` : undefined;
  };

  const getBidPrice = (price: number, currency: string) => {
    const usdPrice = getUSDPrice(currency, price, 0);

    if (usdPrice) {
      return <Typography fontWeight={600} fontSize={"inherit"}>{`~\u00a0${usdPrice}`}</Typography>;
    }
  };

  if (!data) {
    return (
      <CenterWrapper>
        <Loading />
      </CenterWrapper>
    );
  }

  const getSellOrdersCount = () => {
    const sellOrdersCount = previewData?.sellOrders.length ?? 0;
    if (sellOrdersCount === 0) return null;

    const firstSellOrderPrice = previewData?.sellOrders[0].price ?? 0;
    const lastSellOrderPrice = previewData?.sellOrders[sellOrdersCount - 1].price ?? 0;

    return (
      <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]} pt={2}>
        <Box pb={0.5}>
          <Trans i18nKey="exitStrategy.create.sellOrdersCount" values={{ sellOrdersCount }} />
        </Box>
        - {t("exitStrategy.create.sellOrdersFrom")}{" "}
        <strong>{`${renderNumber(firstSellOrderPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}</strong>
        {hasRateForCurrency(counterCurrency) ? ` (~ ${getUSDPrice(counterCurrency, firstSellOrderPrice, currencyPairPriceScale)})` : ""}
        <br />- {t("exitStrategy.create.sellOrdersTo")}{" "}
        <strong>{`${renderNumber(lastSellOrderPrice, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}</strong>
        {hasRateForCurrency(counterCurrency) ? ` (~ ${getUSDPrice(counterCurrency, lastSellOrderPrice, currencyPairPriceScale)})` : ""}
      </Typography>
    );
  };

  const getCalculatedSellOrders = () => {
    if (!previewData || !previewData.sellOrders || !previewData.sellOrders.length) return null;

    const sellOrdersNotEnoughBalance = previewData.sellOrders.filter((order) => order.status === SellOrderStatus.NOT_ENOUGH_BALANCE);
    const totalAmountNotEnoughBalance = sellOrdersNotEnoughBalance.reduce((acc, order) => acc + order.amount, 0);
    const levelsNotEnoughBalance = t("exitStrategy.create.level", { count: sellOrdersNotEnoughBalance.length });

    const sellOrdersEnoughBalance = previewData.sellOrders.filter((order) => order.status === SellOrderStatus.ENOUGH_BALANCE);
    const totalAmountEnoughBalance = sellOrdersEnoughBalance.reduce((acc, order) => acc + order.amount, 0);
    const levelsAmountEnoughBalance = t("exitStrategy.create.level", { count: sellOrdersEnoughBalance.length });

    return (
      <Stack spacing={0.5} pt={2}>
        {totalAmountEnoughBalance ? (
          <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.calculatedSellOrdersEnoughBalance"
              values={{
                baseCurrency,
                exchangeTitle,
                amount: renderNumber(totalAmountEnoughBalance, currencyPairBaseScale, true),
                level: levelsAmountEnoughBalance,
              }}
            />
          </Typography>
        ) : null}
        {totalAmountNotEnoughBalance ? (
          <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.calculatedSellOrdersNotEnoughBalance"
              values={{
                baseCurrency,
                exchangeTitle,
                amount: renderNumber(totalAmountNotEnoughBalance, currencyPairBaseScale, true),
                level: levelsNotEnoughBalance,
              }}
            />
          </Typography>
        ) : null}
        {!totalAmountEnoughBalance ? (
          <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.calculatedSellOrdersNotEnoughForAll"
              values={{
                baseCurrency,
                exchangeTitle,
                amount: renderNumber(totalAmountNotEnoughBalance, currencyPairBaseScale, true),
                level: levelsNotEnoughBalance,
              }}
            /> ⚠️
          </Typography>
        ) : null}
        {totalAmountEnoughBalance && !totalAmountNotEnoughBalance ? (
          <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.calculatedSellOrdersEnoughForAll"
              values={{
                baseCurrency,
                exchangeTitle,
                amount: renderNumber(totalAmountNotEnoughBalance, currencyPairBaseScale, true),
                level: levelsNotEnoughBalance,
              }}
            /> ✅
          </Typography>
        ) : null}
      </Stack>
    );
  };

  const getHighestSellingPrice = () => {
    const fromPrice = Number(formik.values.firstSellingPrice ? `${formik.values.firstSellingPrice}`.replace(",", ".") : 0);
    const increasedValue = increaseByPercentage(fromPrice, formik.values.highestSellingPricePct);
    const customInputValue = new Big(increasedValue).round(currencyPairPriceScale, Big.roundDown).toNumber();

    return (
      <Grid container>
        <Grid item {...leftGridItemProps}>
          <Stack spacing={2}>
            <Typography fontWeight={600} fontSize={"1rem"}>
              {t(`exitStrategy.create.${type}.highestSellingPricePctTitle`)}
            </Typography>
            <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
              <Trans i18nKey={`exitStrategy.create.${type}.highestSellingPricePctDescription`} />
            </Typography>
          </Stack>
        </Grid>
        <Grid item {...rightGridItemProps}>
          <InputWithLog
            id="highestSellingPricePct"
            precision={currencyPairPriceScale}
            label={t(`exitStrategy.create.${type}.highestSellingPricePct`)}
            formik={formik}
            currency={counterCurrency}
            logMin={10}
            logMax={1000}
            helperText={getApproxUsdPrice(customInputValue, counterCurrency)}
            disabledInput
            withIncrease={false}
            valueLabelDisplay="auto"
            customInputValue={customInputValue}
            sliderColor={colors["error"]}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack spacing={6} pt={8}>
      <Grid container>
        <Grid item {...leftGridItemProps}>
          <Stack spacing={2}>
            <Typography fontWeight={600} fontSize={"1rem"}>
              {t(`exitStrategy.create.${type}.totalAmountTitle`)}
            </Typography>
            <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
              <Trans i18nKey={`exitStrategy.create.${type}.totalAmountDescription`} />
            </Typography>
          </Stack>
        </Grid>
        <Grid item {...rightGridItemProps}>
          <InputWithSlider
            id="totalAmount"
            precision={baseCurrScale}
            label={t(`exitStrategy.create.${type}.totalAmount`)}
            formik={formik}
            currency={baseCurrency}
            max={details.totalAmount}
            sliderColor="primary.main"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item {...leftGridItemProps}>
          <Stack spacing={2}>
            <Typography fontWeight={600} fontSize={"1rem"}>
              {t(`exitStrategy.create.${type}.firstSellingPriceTitle`)}
            </Typography>
            <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
              <Trans i18nKey={`exitStrategy.create.${type}.firstSellingPriceDescription`} />
            </Typography>
            <Stack direction={"row"} flexWrap={"wrap"} columnGap={1} alignItems={"center"}>
              <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                {`${t(`dcaBots.bidPrice`)}:`}
              </Typography>
              <Stack direction={"row"} columnGap={1}>
                <RedBadge>
                  <Stack direction={"row"} display={"flex"} alignItems={"center"}>
                    <Sell sx={{ fontSize: "1rem" }} />
                    <Typography fontWeight={600} fontSize={"0.75rem"} pl={0.5}>
                      {`${renderNumber(data?.bidPrice ?? 0, currencyPairPriceScale, true)}\u00a0${counterCurrency}`}
                    </Typography>
                  </Stack>
                </RedBadge>
                {hasRateForCurrency(counterCurrency) && (
                  <RedBadge>
                    <Typography fontWeight={600} fontSize={"0.75rem"}>
                      {getBidPrice(data?.bidPrice ?? 0, counterCurrency)}
                    </Typography>
                  </RedBadge>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Grid>
        <Grid item {...rightGridItemProps}>
          <InputWithLog
            id="firstSellingPrice"
            precision={currencyPairPriceScale}
            label={t(`exitStrategy.create.${type}.firstSellingPrice`)}
            formik={formik}
            currency={counterCurrency}
            baseValue={data.bidPrice}
            helperText={getApproxUsdPrice(formik.values.firstSellingPrice, counterCurrency)}
            sliderColor={colors["error"]}
          />
        </Grid>
      </Grid>

      <Grid container>{getHighestSellingPrice()}</Grid>

      <Grid container>
        <Grid item {...smallerLeftGridItemProps}>
          <Stack spacing={2}>
            <Typography fontWeight={600} fontSize={"1rem"}>
              {t(`exitStrategy.create.${type}.sellingPriceStepPctTitle`)}
            </Typography>
            <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
              <Trans i18nKey={`exitStrategy.create.${type}.sellingPriceStepPctDescription`} />
            </Typography>
            <StepInput
              id={"sellingPriceStepPct"}
              formik={formik}
              step={1}
              min={sellingPriceStepPctMin[subscriptionType ?? SubscriptionType.FREE]}
              max={10}
              suffix={"%"}
              stackSx={{ pt: 2 }}
            />
            {subscriptionType !== SubscriptionType.PREMIUM && (
              <Alert
                style={{ backgroundColor: colors.purple100, color: colors.purple500 }}
                icon={<Diamond style={{ color: colors.purple500 }} />}>
                <AlertTitle>
                  <Trans i18nKey="exitStrategy.create.sellingPriceStepPctUpsell.title" />
                </AlertTitle>
                <Trans i18nKey={`exitStrategy.create.sellingPriceStepPctUpsell.description.${subscriptionType}`} />
              </Alert>
            )}
            {getSellOrdersCount()}
          </Stack>
        </Grid>
        <Grid item {...largerRightGridItemProps}>
          <Chart
            scale={currencyPairPriceScale}
            counterCurrency={counterCurrency}
            exchangeEnum={exchangeEnum}
            currencyPair={currencyPair}
            data={previewData}
            isBrokerage={isBrokerage}
          />
          {getCalculatedSellOrders()}
        </Grid>
      </Grid>

      {type === ExitStrategyType.PCT_OF_GROWTH_SELL && (
        <Grid container>
          <Grid container>
            <Grid item {...leftGridItemProps}>
              <Stack spacing={2}>
                <Typography fontWeight={600} fontSize={"1rem"}>
                  <Trans i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellTitle`} />
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans
                    i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellDescription1`}
                    values={{ baseCurrency, counterCurrency }}
                  />
                </Typography>
              </Stack>
            </Grid>
            <Grid item {...rightGridItemProps}>
              <InputWithLog
                id="pctOfGrowthToSell"
                precision={0}
                label={t(`exitStrategy.create.${type}.pctOfGrowthToSell`)}
                formik={formik}
                currency={"%"}
                logMin={1}
                logMax={500}
                disabledInput
                withIncrease={false}
                sliderColor={colors["error"]}
              />
            </Grid>
            <Grid item xs={12} pt={2}>
              <Stack spacing={2}>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans
                    i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellDescription2`}
                    values={{ baseCurrency, counterCurrency }}
                  />
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans
                    i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellDescription3`}
                    values={{ baseCurrency, counterCurrency }}
                  />
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans
                    i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellDescription4`}
                    values={{ baseCurrency, counterCurrency }}
                  />
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans i18nKey={`exitStrategy.create.${type}.pctOfGrowthToSellDescription5`} />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      )}

      {previewData?.sellOrders && previewData?.sellOrders.length > 1 ? (
        <Grid container>
          <Grid item xs={12}>
            <OverviewChart previewData={previewData} currencyPair={currencyPair} exchangeEnum={exchangeEnum} />
          </Grid>
        </Grid>
      ) : null}
    </Stack>
  );
};

export default Settings;
