import React, { useEffect, useRef, useState } from "react";

import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Pagination,
  Stack,
  Typography,
  darken,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Panel from "components/elements/Panel";
import Switch from "components/elements/Switch";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { Sell, Buy, ArrowUp, ArrowDown } from "components/icons";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { DcaBotOrderStatus, LayoutPosition, Side } from "types/enums";
import { IOrder } from "types/types";
import { getColorByMode } from "utils";
import { renderDateTimeWithComma } from "utils/formatter";

import StatusBadge from "./StatusBadge";
import { CustomBox } from "./styles";
import IProps from "./types";

const rowsPerPage = 20;

const OrdersList = ({ data, counterCurrency, baseCurrency, exchangeEnum, enabled, position, onPositionToggle }: IProps) => {
  const { colors } = useMUIThemeModeContext();
  const theme = useTheme();
  const orderListRef = useRef<any>();
  const { t } = useTranslation();
  const orderRowBreakpoint = useMediaQuery(theme.breakpoints.down(970));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("lg"));
  const { currencyPairBaseScale, baseCurrDisplayedScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );

  const [orders, setOrders] = useState<IOrder[]>();
  const [page, setPage] = useState<number>(0);

  const showOpenOrders = localStorage.getItem("showOpenOrders");
  const [showOpenOrder, setShowOpenOrder] = useState<boolean>(showOpenOrders ? showOpenOrders === "true" : true);

  const [ordersData, setOrdersData] = useState<IOrder[] | undefined>();

  const sliceData = (nextPage = page, nextRowsPerPage = rowsPerPage) => {
    if (ordersData) {
      setOrders(ordersData.slice(nextPage * nextRowsPerPage, nextPage * nextRowsPerPage + nextRowsPerPage));
    }
  };

  useEffect(() => {
    if (data?.orders)
      setOrdersData(showOpenOrder ? data?.orders : data?.orders.filter((el) => el.status !== DcaBotOrderStatus.OPEN));
  }, [data?.orders]);

  useEffect(() => {
    setOrdersData(showOpenOrder ? data?.orders : data?.orders.filter((el) => el.status !== DcaBotOrderStatus.OPEN));
  }, [showOpenOrder]);

  useEffect(() => {
    sliceData(page, rowsPerPage);
  }, [ordersData]);

  const handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
    sliceData(newPage - 1);
    orderListRef.current?.scrollIntoView?.();
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOpenOrder(event.target.checked);
    localStorage.setItem("showOpenOrders", `${event.target.checked}`);
    setPage(0);
    sliceData(0);
  };

  const getDate = (order: IOrder) => {
    if (order.cumulativeAmount === 0) return order.calculatedAt;

    return order.filledAt;
  };

  const row = (order: IOrder) => {
    return (
      <CustomBox key={order.id}>
        <Stack
          width={"100%"}
          spacing={orderRowBreakpoint ? 2 : 3}
          direction={orderRowBreakpoint ? "column" : "row"}
          alignItems={"center"}>
          {orderRowBreakpoint ? (
            <>
              <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                <Stack alignItems={"flex-start"}>
                  <Stack spacing={1.5} direction={"row"}>
                    {order.side === Side.BUY ? <Buy sx={{ fontSize: "1.25rem" }} /> : <Sell sx={{ color: "#D92D20", fontSize: "1.25rem" }} />}
                    <Typography variant="subtitle2" color={colors.gray950} fontWeight={600}>
                      {t(`gridBot.orderList.${order.side}`)}
                    </Typography>
                  </Stack>
                  <Typography variant="caption" color={getColorByMode(theme, colors.gray400, colors.gray600)}>
                    {renderDateTimeWithComma(getDate(order))}
                  </Typography>
                </Stack>
                <Stack alignItems={"flex-end"}>
                  <MaskedModeWrapper color={colors.gray950} fontWeight={600} variant="subtitle2">
                    <SatsView
                      color={colors.gray950}
                      variant="subtitle2"
                      fontWeight={600}
                      value={order.cumulativeAmount > 0 ? order.cumulativeAmount : order.originalAmount}
                      currency={baseCurrency ?? ""}
                      scale={baseCurrDisplayedScale}
                      prefix={order.side === Side.BUY ? "+" : "-"}
                      withZeros
                    />
                  </MaskedModeWrapper>
                  <UsdWrapper value={order.originalPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
                    <TypographyItem
                      variant="caption"
                      color={getColorByMode(theme, colors.gray400, colors.gray600)}
                      value={order.originalPrice}
                      scale={currencyPairBaseScale}
                      currency={counterCurrency}
                      prefix="@ "
                    />
                  </UsdWrapper>
                </Stack>
              </Stack>
              <Stack width={"100%"} direction={"row"} justifyContent={"space-between"}>
                <Stack alignItems={"flex-start"}>
                  <StatusBadge status={order.status} />
                </Stack>
                <Stack alignItems={"flex-end"}>
                  <MaskedModeWrapper color={colors.gray950} fontWeight={600} variant="subtitle2">
                    <SatsView
                      color={colors.gray950}
                      variant="subtitle2"
                      fontWeight={600}
                      value={order.relatedOrderBaseCurrencyProfit}
                      currency={baseCurrency ?? ""}
                      scale={baseCurrDisplayedScale}
                      prefix={order.relatedOrderBaseCurrencyProfit ? "+" : undefined}
                      fallbackValue={"-"}
                      withZeros
                    />
                  </MaskedModeWrapper>
                  <Typography variant="caption" color={getColorByMode(theme, colors.gray400, colors.gray600)}>
                    {t(`gridBot.orderList.orderProfit`)}
                  </Typography>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              {
                order.side === Side.BUY
                  ? <Buy sx={{ fontSize: "1.25rem" }} />
                  : <Sell sx={{ color: "#D92D20", fontSize: "1.25rem" }} />}
              <Stack>
                <Typography variant="subtitle2" color={colors.gray950} fontWeight={600}>
                  {t(`gridBot.orderList.${order.side}`)}
                </Typography>
                <Typography variant="caption" color={getColorByMode(theme, colors.gray400, colors.gray600)}>
                  {renderDateTimeWithComma(getDate(order))}
                </Typography>
              </Stack>
              <Stack flex={1} alignItems={"flex-end"}>
                <MaskedModeWrapper color={colors.gray950} fontWeight={600} variant="subtitle2">
                  <SatsView
                    color={colors.gray950}
                    variant="subtitle2"
                    fontWeight={600}
                    value={order.cumulativeAmount > 0 ? order.cumulativeAmount : order.originalAmount}
                    currency={baseCurrency ?? ""}
                    scale={baseCurrDisplayedScale}
                    prefix={order.side === Side.BUY ? "+" : "-"}
                    withZeros
                  />
                </MaskedModeWrapper>
                <UsdWrapper value={order.originalPrice} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
                  <TypographyItem
                    variant="caption"
                    color={getColorByMode(theme, colors.gray400, colors.gray600)}
                    value={order.originalPrice}
                    scale={currencyPairBaseScale}
                    currency={counterCurrency}
                    prefix="@ "
                  />
                </UsdWrapper>
              </Stack>
              <Stack flex={1} alignItems={"flex-end"}>
                <MaskedModeWrapper color={colors.gray950} fontWeight={600} variant="subtitle2">
                  <SatsView
                    color={colors.gray950}
                    variant="subtitle2"
                    fontWeight={600}
                    value={order.relatedOrderBaseCurrencyProfit}
                    currency={baseCurrency ?? ""}
                    scale={baseCurrDisplayedScale}
                    prefix={order.relatedOrderBaseCurrencyProfit ? "+" : undefined}
                    fallbackValue={"-"}
                    withZeros
                  />
                </MaskedModeWrapper>
                <Typography variant="caption" color={getColorByMode(theme, colors.gray400, colors.gray600)}>
                  {t(`gridBot.orderList.orderProfit`)}
                </Typography>
              </Stack>
              <Stack flex={1} alignItems={"flex-start"}>
                <StatusBadge status={order.status} />
              </Stack>
            </>
          )}
        </Stack>
      </CustomBox>
    );
  };

  const noOrders = () => (
    <Grid container sx={{ justifyContent: "center", padding: "16px" }}>
      <Typography variant="body1">{t("gridBot.orderList.empty")}</Typography>
    </Grid>
  );

  const ordersPagination = () => {
    const rowCount = ordersData?.length ? Math.ceil(ordersData?.length / rowsPerPage) : 0;

    if (rowCount <= 1) return;

    return (
      <Box py={2} display={"flex"} justifyContent={"center"}>
        <Pagination
          count={rowCount}
          siblingCount={isMobile ? 0 : undefined}
          boundaryCount={isMobile ? 1 : undefined}
          page={page + 1}
          onChange={handleChangePage}
          size={isMobile ? "small" : "medium"}
          color="primary"
        />
      </Box>
    );
  };

  return (
    <Panel sx={{ overflow: "hidden" }}>
      <Box px={3} py={2.5} sx={{ borderBottom: `1px solid ${colors.gray200}` }}>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
            {t("gridBot.orderList.title")}
          </Typography>
          {isMobileLayout && (
            <IconButton onClick={onPositionToggle} size="small">
              {position === LayoutPosition.BOTTOM ? <ArrowUp fontSize="inherit" /> : <ArrowDown fontSize="inherit" />}
            </IconButton>
          )}
        </Stack>
      </Box>
      {data?.orders?.length ? (
        <>
          {enabled && (
            <Box
              pl={4}
              pr={1}
              py={2}
              sx={{
                borderBottom: `1px solid ${colors.gray200}`,
                backgroundColor: getColorByMode(theme, colors.gray50, darken(colors.gray50, 0.4)),
              }}>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    variant: "subtitle2",
                    color: colors.gray700,
                  },
                }}
                control={<Switch sx={{ mr: 1 }} checked={showOpenOrder} onChange={handleChangeCheckbox} />}
                label={t("gridBot.orderList.showOpenOrder")}
              />
            </Box>
          )}
          {orders?.length ? (
            <>
              <Stack direction={"column"} ref={orderListRef}>
                {orders.map((order) => row(order))}
              </Stack>
              {ordersPagination()}
            </>
          ) : (
            noOrders()
          )}
        </>
      ) : (
        noOrders()
      )}
    </Panel>
  );
};

export default OrdersList;
