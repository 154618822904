import React, { useEffect, useState } from "react";

import { Typography, Stack, Box, Button } from "@mui/material";
import Panel from "components/elements/Panel";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { Buy, Sell } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { GridPriceLevelStatus, Side } from "types/enums";
import { IPriceLevel } from "types/types";
import { splitCurrencyPair } from "utils";

import StatusBadge from "../OrdersList/StatusBadge";
import { ItemBox, GapBox, LineBox, HiddenBox } from "./styles";
import IProps, { ILevelsContent } from "./types";

export const LevelsContent = ({ priceLevels, counterCurrency, baseCurrency, counterCurrDisplayedScale }: ILevelsContent) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  const [showAll, setShowAll] = useState<boolean>(false);
  const [levelData, setLevelData] = useState<IPriceLevel[]>([]);
  const [hiddenSellCount, setHiddenSellCount] = useState<number>(0);
  const [hiddenBuyCount, setHiddenBuyCount] = useState<number>(0);

  useEffect(() => {
    if (priceLevels && !showAll) {
      const index = priceLevels.findIndex((level) => level.priceLevelStatus === GridPriceLevelStatus.GAP);
      const startIndex = index - 5 < 0 ? 0 : index - 5;
      const result = priceLevels.slice(startIndex, index + 6);

      setLevelData(result);
      setHiddenSellCount(startIndex);
      setHiddenBuyCount(priceLevels.length - index - 6);
    }

    if (priceLevels && showAll) setLevelData(priceLevels);
  }, [priceLevels, showAll]);

  const getLevel = (level: IPriceLevel, index: number) => {
    const isNextGap = levelData[index + 1]?.priceLevelStatus === GridPriceLevelStatus.GAP;

    if (level.priceLevelStatus === GridPriceLevelStatus.GAP) {
      return (
        <Box key={`level-${level.value}`} py={0.5}>
          <Stack direction={"row"} alignItems={"center"}>
            <GapBox>
              <UsdWrapper value={level.value} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
                <TypographyItem
                  variant="caption"
                  value={level.value}
                  scale={counterCurrDisplayedScale}
                  currency={counterCurrency}
                />
              </UsdWrapper>
            </GapBox>
            <LineBox />
          </Stack>
        </Box>
      );
    }

    return (
      <ItemBox key={`level-${level.value}`} isGap={isNextGap}>
        <Stack width={"100%"} spacing={3} direction={"row"} alignItems={"center"}>
          {level.orderSide === Side.BUY ? (
            <Buy sx={{ fontSize: "1.25rem" }} />
          ) : (
            <Sell sx={{ color: "#D92D20", fontSize: "1.25rem", visibility: level.orderSide ? "visibility" : "hidden" }} />
          )}
          <Box flex={1}>
            <UsdWrapper value={level.value} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                variant="subtitle2"
                color={colors.gray700}
                fontFamily={"monospace"}
                fontWeight={600}
                value={level.value}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
              />
            </UsdWrapper>
          </Box>
          {level.priceLevelStatus && (
            <StatusBadge
              status={level.priceLevelStatus}
              currency={level.orderSide === Side.BUY ? counterCurrency : baseCurrency}
            />
          )}
        </Stack>
      </ItemBox>
    );
  };

  return (
    <>
      {!showAll && hiddenSellCount > 0 && (
        <HiddenBox>
          <Typography color={colors.gray700} variant="caption">
            {t("gridBot.nextSellLevels", { count: hiddenSellCount })}
          </Typography>
        </HiddenBox>
      )}
      {levelData.map((level, index) => getLevel(level, index))}
      {!showAll && hiddenBuyCount > 0 && (
        <HiddenBox>
          <Typography color={colors.gray700} variant="caption">
            {t("gridBot.nextBuyLevels", { count: hiddenBuyCount })}
          </Typography>
        </HiddenBox>
      )}
      {!showAll && (hiddenSellCount > 0 || hiddenBuyCount > 0) && (
        <Box px={3} py={2.5} display={"flex"} justifyContent={"center"}>
          <Button variant="contained" onClick={() => setShowAll(true)}>
            {t("gridBot.showAllLevels")}
          </Button>
        </Box>
      )}
    </>
  );
};

const Levels: React.FC<IProps> = ({ data, exchangeEnum, currencyPair, title }) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { counterCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", currencyPair);
  const { counterCurrency, baseCurrency } = splitCurrencyPair(currencyPair);

  const getTitle = () => {
    if (!title) {
      return (
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
          {t("gridBot.gridLevels")}
        </Typography>
      );
    }

    if (typeof title === "string") {
      return (
        <Typography variant="h5" component="h1" fontWeight={600} sx={{ color: "tertiary.main" }}>
          {title}
        </Typography>
      );
    }

    return title;
  };

  return (
    <Panel sx={{ overflow: "hidden" }}>
      <Box px={3} py={2.5} sx={{ borderBottom: data?.priceLevels?.length ? `1px solid ${colors.gray200}` : "none" }}>
        {getTitle()}
        <Stack pt={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body2" pr={1}>
              {t("dcaBots.askPrice")}
            </Typography>
            <UsdWrapper value={data?.askPrice ?? 0} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body2"
                value={data?.askPrice ?? 0}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                fontFamily={"monospace"}
              />
            </UsdWrapper>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color={"secondary"} variant="body2" pr={1}>
              {t("dcaBots.bidPrice")}
            </Typography>
            <UsdWrapper value={data?.bidPrice ?? 0} counterCurrency={counterCurrency} baseCurrency={baseCurrency}>
              <TypographyItem
                sx={{ textAlign: "end" }}
                fontWeight={600}
                color={"secondary"}
                variant="body2"
                value={data?.bidPrice ?? 0}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                fontFamily={"monospace"}
              />
            </UsdWrapper>
          </Stack>
        </Stack>
      </Box>
      <LevelsContent
        priceLevels={data?.priceLevels}
        counterCurrency={counterCurrency}
        baseCurrency={baseCurrency}
        counterCurrDisplayedScale={counterCurrDisplayedScale}
      />
    </Panel>
  );
};

export default Levels;
