import { VIP_USERS } from "constants/affiliate";
import { subscriptionDcaBots, subscriptionBots } from "constants/subscriptions";

import { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Alert, AlertTitle, Box, Button, CircularProgress, Collapse, Grid, IconButton, Stack, Typography } from "@mui/material";
import CenterWrapper from "components/elements/CenterWrapper";
import { Settings3 } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SubscriptionType } from "types/enums";
import { getLocalStorageBooleanValue, hasAllValuesFalse, isEmptyArray } from "utils";

import Item from "./Item";
import ListWrapper from "./ListWrapper";
import Summaries from "./Summaries";
import EditModal from "./Summaries/EditModal";
import TrezorAffiliateTile from "./TrezorAffiliateTile";
import { IProps } from "./types";

const BotsList = ({ dcaBotsData, botsData, dcaBotsMutate, botsMutate, strategiesData }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { subscriptionType, summariesSettings, aff} = useUserDataContext();
  const [isDisabledListCollapsed, setIsDisabledListCollapsed] = useState<boolean>(getLocalStorageBooleanValue("disabledListCollapsed"));
  const [summariesSettingsModalOpen, setSummariesSettingsModalOpen] = useState<boolean>(false);

  // STATE FOR TEMP ALERTS
  // const [alertCreditsTaxLaw, setAlertCreditsTaxLaw] = useState<boolean>(getLocalStorageBooleanValue("showAlertCreditsTaxLaw", true));

  if (!dcaBotsData || !botsData) {
    return (
      <CenterWrapper>
        <CircularProgress color="primary" />
      </CenterWrapper>
    );
  }

  const hasEmptySummaries = () => {
    return (
      strategiesData?.strategiesSummary?.dcaStrategiesSummaries
      && !strategiesData?.strategiesSummary?.dcaStrategiesSummaries.length
      && strategiesData?.strategiesSummary?.gridStrategiesSummaries
      && !strategiesData?.strategiesSummary?.gridStrategiesSummaries.length
    );
  };

  const handleDisabledListCollapse = () => {
    localStorage.setItem("disabledListCollapsed", (!isDisabledListCollapsed).toString());
    setIsDisabledListCollapsed(!isDisabledListCollapsed);
  };

  const canRenderSummariesSettingsButton = () =>
    subscriptionType === SubscriptionType.PREMIUM && (hasAllValuesFalse(summariesSettings) || hasEmptySummaries());

  const enabledListHeader = () => (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Typography color={colors.gray900} fontWeight={500} fontSize={{ sx: "1.125rem", sm: "1.375rem" }}>
        {t("bots.listHeader.enabled")}
      </Typography>
      {canRenderSummariesSettingsButton() && (
        <IconButton size="small" onClick={() => setSummariesSettingsModalOpen(true)}>
          <Settings3 sx={{ fontSize: 18, color: colors.gray700 }} />
        </IconButton>
      )}
    </Stack>
  );

  const disabledListHeader = (enabledList: React.ReactNode[]) => (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <Typography color={colors.gray900} fontWeight={500} fontSize={{ sx: "1.125rem", sm: "1.375rem" }}>
        {t("bots.listHeader.disabled")}
      </Typography>
      {canRenderSummariesSettingsButton() && isEmptyArray(enabledList) && (
        <IconButton size="small" onClick={() => setSummariesSettingsModalOpen(true)}>
          <Settings3 sx={{ fontSize: 18, color: colors.gray700 }} />
        </IconButton>
      )}
      <IconButton size="small" onClick={handleDisabledListCollapse}>
        {isDisabledListCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
    </Stack>
  );

  const canAddNewDcaBot = () =>
    !(subscriptionType && dcaBotsData && subscriptionDcaBots[subscriptionType] <= dcaBotsData.dcaBots.length);

  const canAddNewBot = () => {
    const isVipUser = VIP_USERS.includes(aff);
    if (isVipUser) return true;

    return !(
      subscriptionType
      && botsData
      && subscriptionBots[subscriptionType] <= botsData.bots.filter((el) => el.enabled).length
    );
  };

  const renderList = () => {
    const enabledList: React.ReactNode[] = [];
    const disabledList: React.ReactNode[] = [];

    botsData.bots.forEach((bot, index) =>
      bot.enabled
        ? enabledList.push(
            <Item
              isGrid
              key={`BotItem-${index}`}
              data={bot}
              refreshData={botsMutate}
              strategyData={strategiesData?.gridStrategies[bot.id]}
            />
          )
        : disabledList.push(
            <Item
              isGrid
              key={`BotItem-${index}`}
              data={bot}
              refreshData={botsMutate}
              strategyData={strategiesData?.gridStrategies[bot.id]}
            />
          )
    );

    dcaBotsData.dcaBots.forEach((dcaBot, index) =>
      dcaBot.enabled
        ? enabledList.push(
            <Item
              key={`DcaBotItem-${index}`}
              data={dcaBot}
              refreshData={dcaBotsMutate}
              strategyData={strategiesData?.dcaStrategies[dcaBot.id]}
            />
          )
        : disabledList.push(
            <Item
              key={`DcaBotItem-${index}`}
              data={dcaBot}
              refreshData={dcaBotsMutate}
              strategyData={strategiesData?.dcaStrategies[dcaBot.id]}
            />
          )
    );

    const handleCloseTempAlert = (alert: string) => {
      switch (alert) {
        // case "alertCreditsTaxLaw":
        //   setAlertCreditsTaxLaw(false);
        //   localStorage.setItem("showAlertCreditsTaxLaw", "false");
        //   break;
        default:
          break;
      }
    };

    const tempAlerts = () => {
      return (
        <>
          {/*alertCreditsTaxLaw*/ false && (
            <Alert severity="info" sx={{ mb: 2 }} onClose={() => handleCloseTempAlert("alertCreditsTaxLaw")}>
              <AlertTitle>
                {`50%\u00A0sleva na balíček 10\u00A0000\u00A0Štosáků`}
              </AlertTitle>
              <Stack spacing={2}>
                <Box>
                  <Trans>
                    {`<strong>Od 15.\u00A02.\u00A02025 nabyl účinnost zákon o časovém a hodnotovém testu</strong> (příznivější danění), tak jako oslavu pro tebe máme balíček s <strong>10\u00A0000\u00A0Štosáky</strong> na vyzkoušení platby bitcoinem!\u00A0🧡 Akce bude platit jen <strong>několik\u00A0dní</strong>.\u00A0⏱️`}
                  </Trans>
                </Box>
                <Box>
                  <Stack spacing={1} direction={"row"}>
                    <Button
                      component={Link}
                      to="/stosaky#nakoupit"
                      variant="contained"
                      size="small"
                      color="warning">
                      {"Balíček ve slevě"}
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      color="info"
                      onClick={() => handleCloseTempAlert("alertCreditsTaxLaw")}>
                      {"Zavřít"}
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Alert>
          )}
        </>
      );
    };

    return (
      <Box width={"100%"}>
        <Summaries data={strategiesData?.strategiesSummary} onOpenModal={() => setSummariesSettingsModalOpen(true)} />
        {(!isEmptyArray(disabledList) || !isEmptyArray(enabledList)) && tempAlerts()}
        <ListWrapper header={enabledListHeader()} canAddNewDcaBot={canAddNewDcaBot()} canAddNewBot={canAddNewBot()}>
          {!isEmptyArray(enabledList) && (
            <Grid container spacing={2}>
              {enabledList}
              {<TrezorAffiliateTile key={"TrezorAffiliateTile"} />}
            </Grid>
          )}
        </ListWrapper>
        {!isEmptyArray(disabledList) && (
          <ListWrapper isDisabled header={disabledListHeader(enabledList)}>
            <Collapse in={isDisabledListCollapsed}>
              <Grid container spacing={2}>
                {disabledList}
                {isEmptyArray(enabledList) && <TrezorAffiliateTile key={"TrezorAffiliateTile"} />}
              </Grid>
            </Collapse>
          </ListWrapper>
        )}
        {summariesSettingsModalOpen && <EditModal open={true} onCancel={() => setSummariesSettingsModalOpen(false)} />}
      </Box>
    );
  };

  return renderList();
};

export default BotsList;
