import { useState, useEffect } from "react";

import { Divider, IconButton, useMediaQuery, useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "components/elements/Card";
import FormToggleButton from "components/elements/FormToggleButton";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { ArrowDown, ArrowUp } from "components/icons";
import { useUserDataContext } from "context/UserDataContext";
import useCurrencyPairs from "hooks/useCurrencyPairs";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation, Trans } from "react-i18next";
import { DcaBotMode, DcaBotRunState, LayoutPosition, OrderTriggering, OrderType } from "types/enums";
import { IDcaBotRun, IRunsListOrder } from "types/types";
import { getLocalStorageBooleanValue } from "utils";
import { renderNumber } from "utils/formatter";

import Chart from "./Chart";
import Filter, { IFilter } from "./Filter";
import OpenLimitRow from "./OpenLimitRow";
import OrderRow from "./OrderRow";
import Summary from "./Summary";
import IProps from "./types";

const checkIfRunIsEmpty = (orders: IRunsListOrder[]) => {
  if (orders.length === 0) return true;

  let baseSums = 0;
  let counterSums = 0;

  orders.forEach((order) => {
    if (["FILLED", "PARTIALLY_FILLED", "PARTIALLY_CANCELED"].includes(order.status)) {
      baseSums += order.cumulativeAmount;
      counterSums += order.cumulativeValue;
    }
  });

  if (baseSums === 0 || counterSums === 0) return true;

  return false;
};

const RunsList = ({
  data,
  initialBaseCurrencyAmount,
  initialCounterCurrencyAmount,
  baseCurrency,
  counterCurrency,
  exchangeEnum,
  setInitialValuesModalOpen,
  dcaBotId,
  refreshData,
  position,
  onPositionToggle,
  refreshDetail,
  isBrokerage,
  strategyType,
  botDetail,
}: IProps) => {
  const { mode, enabled, exitStrategy, details } = botDetail;
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobileLayout = useMediaQuery(theme.breakpoints.down("lg"));
  const disableRunsListInfoBox = localStorage.getItem("disableRunsListInfoBox");
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [activeFilters, setActiveFilters] = useState<IFilter>({ side: [], orderTriggering: [] });
  const [countEmptyRuns, setCountEmptyRuns] = useState<number>(0);
  const [rowData, setRowData] = useState<IDcaBotRun[]>();
  const [close, setClose] = useState(false);
  const { isMasked } = useUserDataContext();
  const { counterCurrDisplayedScale, currencyPairBaseScale } = useExchangesMetadata(
    exchangeEnum ?? "",
    `${baseCurrency}/${counterCurrency}`
  );
  const [canCreateExitStrategy, setCanCreateExitStrategy] = useState<boolean>(false);
  const { data: currencyPairs } = useCurrencyPairs();

  useEffect(() => {
    if (data) {
      let rowDataFiltered = data.dcaBotRuns
        .filter((dcaBotRun) => {
          // Do not show open limit orders in the list
          if (dcaBotRun.state === DcaBotRunState.OPEN && dcaBotRun.triggering === OrderTriggering.INSTANT && dcaBotRun.type === OrderType.LIMIT) {
            return false;
          }

          // Filter by side
          if (activeFilters.side.length && activeFilters.side.length !== 2 && !activeFilters.side.includes(dcaBotRun.side)) {
            return false;
          }

          // Filter by order triggering
          if (activeFilters.orderTriggering.length && !activeFilters.orderTriggering.includes(dcaBotRun.triggering)) {
            return false;
          }

          return true;
        })
        .sort((a, b) => {
          const dateA = a.startedAt ?? a.scheduledAt;
          const dateB = b.startedAt ?? b.scheduledAt;

          const timestampA = dateA ? new Date(dateA).getTime() : 0;
          const timestampB = dateB ? new Date(dateB).getTime() : 0;

          if (order === "asc") {
            return timestampA - timestampB;
          }
          return timestampB - timestampA;
        });

      // Count empty runs
      const emptyRuns = rowDataFiltered.filter((dcaBotRun) => checkIfRunIsEmpty(dcaBotRun.orders));
      setCountEmptyRuns(emptyRuns.length);
      
      // Filter by empty runs
      if (activeFilters.empty === "SHOW") rowDataFiltered = emptyRuns;
      if (activeFilters.empty === "HIDE") rowDataFiltered = rowDataFiltered.filter((dcaBotRun) => !checkIfRunIsEmpty(dcaBotRun.orders));

      setRowData(rowDataFiltered);
    }
  }, [data, order, activeFilters]);

  useEffect(() => {
    if (currencyPairs.length && exchangeEnum && !canCreateExitStrategy) {
      const exchangeMetadata = currencyPairs.find((item) => item.id === exchangeEnum);
      const currencyPairMetadata = exchangeMetadata.data.find((item: any) => item.key === `${baseCurrency}/${counterCurrency}`);

      if (currencyPairMetadata?.enabled_exit_strategies?.length) {
        setCanCreateExitStrategy(true);
      }
    }
  }, [currencyPairs, exchangeEnum]);

  const closeInfoBox = () => {
    localStorage.setItem("disableRunsListInfoBox", "true");
    setClose(true);
  };

  const renderOpenLimitOrders = () => {
    if (!data || data?.dcaBotRuns.length === 0) return;

    const openLimitOrders = data.dcaBotRuns
      .filter((dcaBotRun) => {
        return (
          dcaBotRun.state === DcaBotRunState.OPEN
          && dcaBotRun.triggering === OrderTriggering.INSTANT
          && dcaBotRun.type === OrderType.LIMIT
        );
      })
      .sort((a, b) => b?.orders?.[0]?.originalPrice - a?.orders?.[0]?.originalPrice);

    if (openLimitOrders.length === 0) return;

    return (
      <Stack spacing={2} pb={2} mx={{ xs: -1, sm: 0 }}>
        <Typography component="h1" variant="body1" fontWeight={500} sx={{ color: "secondary.main" }}>
          {t("dcaBots.runsList.openLimitTitle")}
        </Typography>
        {openLimitOrders.map((dcaBotRun, index) => (
          <OpenLimitRow
            key={`dcaBotOpenLimit-${dcaBotRun.startedAt}-${index}`}
            dcaBotRun={dcaBotRun}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
            exchangeEnum={exchangeEnum}
            dcaBotId={dcaBotId}
            refreshData={refreshData}
          />
        ))}
        <Divider />
      </Stack>
    );
  };

  const renderTable = () => {
    if (!rowData) {
      return (
        <Grid container sx={{ justifyContent: "center", padding: "16px" }}>
          <CircularProgress color="primary" />
        </Grid>
      );
    }

    if (rowData.length === 0) {
      return (
        <Grid container sx={{ justifyContent: "center", padding: "16px" }}>
          <Typography variant="body1">{t("dcaBots.runsList.noData")}</Typography>
        </Grid>
      );
    }

    const rowDataToDisplay = isShowAll ? rowData : rowData.slice(0, 5);

    return (
      <Stack spacing={2} mx={{ xs: -1, sm: 0 }}>
        {rowDataToDisplay.map((dcaBotRun, index) => (
          <OrderRow
            key={`dcaBotRun-${dcaBotRun.startedAt}-${index}`}
            dcaBotRun={dcaBotRun}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
            defaultOpen={index === 0}
            exchangeEnum={exchangeEnum}
            refreshDetail={refreshDetail}
          />
        ))}
      </Stack>
    );
  };

  const handleSort = (sort: string | string[] | null) => {
    if (sort !== null) setOrder(sort as "asc" | "desc");
  };

  const filter = () => {
    return (
      <Stack direction={"row"} spacing={{ xs: 1, md: 0 }} justifyContent={"space-between"} pb={3}>
        <FormToggleButton
          value={order}
          onChange={(_event, newOrder) => handleSort(newOrder)}
          size="small"
          color="primary"
          items={[
            { value: "desc", label: t("dcaBots.runsList.triggering.desc") },
            { value: "asc", label: t("dcaBots.runsList.triggering.asc") },
          ]}
          disabled={data?.dcaBotRuns.length === 0 || !data}
        />
        <Filter
          value={activeFilters}
          onChange={setActiveFilters}
          disabled={data?.dcaBotRuns.length === 0 || !data}
          countEmpty={countEmptyRuns}
        />
      </Stack>
    );
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={t("dcaBots.runsList.title")}
      actions={
        <Button color="info" onClick={setInitialValuesModalOpen} variant="outlined" size="small">
          {initialBaseCurrencyAmount !== 0 && initialCounterCurrencyAmount !== 0
            ? t("dcaBots.initialValues.ctaEdit")
            : t("dcaBots.initialValues.cta")}
        </Button>
      }
      leftActions={
        isMobileLayout && (
          <IconButton onClick={onPositionToggle} size="small">
            {position === LayoutPosition.BOTTOM ? <ArrowUp fontSize="inherit" /> : <ArrowDown fontSize="inherit" />}
          </IconButton>
        )
      }>
      {exchangeEnum ? (
        <Chart
          exchangeEnum={exchangeEnum}
          currencyPair={`${baseCurrency}/${counterCurrency}`}
          isBrokerage={isBrokerage}
          askPrice={details?.askPrice}
          bidPrice={details?.bidPrice}
          dcaBotRuns={data?.dcaBotRuns}
          mode={mode}
          strategyType={strategyType}
          enabled={enabled}
          exitStrategy={exitStrategy}
        />
      ) : null}
      {data && data.summary && data.summary.totalOrdersCount > 0 && (
        <Summary
          data={data.summary}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          exchangeEnum={exchangeEnum}
          currentValue={details?.currentValue}
          totalAmount={details?.totalAmount}
        />
      )}
      {filter()}
      {renderOpenLimitOrders()}
      {!disableRunsListInfoBox && !close && mode !== DcaBotMode.MANUAL && (
        <Alert
          onClose={closeInfoBox}
          severity="info"
          sx={{ mb: 2, "@media (max-width:400px)": { fontSize: "0.75rem" } }}>
          <AlertTitle>
            <Trans i18nKey="dcaBots.runsList.infoBox.title" />
          </AlertTitle>
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle1" />
          <br />
          <br />
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle2" values={{ baseCurrency }} />
          <br />
          <br />
          <Trans i18nKey="dcaBots.runsList.infoBox.subtitle3" />
        </Alert>
      )}
      {renderTable()}
      {!isShowAll && rowData && rowData.length > 5 && (
        <Box mt={3} display={"flex"} justifyContent={"center"}>
          <Button sx={{ backgroundColor: "tertiary.main" }} onClick={() => setIsShowAll(true)} variant="contained">
            {t("dcaBots.runsList.loadAll")}
          </Button>
        </Box>
      )}
      {!!(initialBaseCurrencyAmount && initialCounterCurrencyAmount) && (
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Trans
            i18nKey="dcaBots.runsList.initialAmounts"
            values={{
              initialBaseCurrencyAmount: getMaskedValue(
                isMasked,
                `${renderNumber(initialBaseCurrencyAmount, currencyPairBaseScale)}\u00a0${baseCurrency}`
              ),
              initialCounterCurrencyAmount: getMaskedValue(
                isMasked,
                `${renderNumber(initialCounterCurrencyAmount, counterCurrDisplayedScale, true)}\u00a0${counterCurrency}`
              ),
            }}
          />
        </Box>
      )}
    </Card>
  );
};

export default RunsList;
