/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { subscriptionDcaBots, subscriptionLimitOrders } from "constants/subscriptions";

import { Alert, AlertTitle, Box, Checkbox, CircularProgress, FormControlLabel, Link as MUILink, Stack, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AspectRatioWrapper from "components/elements/AspectRatioWrapper";
import BackdropLoading from "components/elements/BackdropLoading";
import Card from "components/elements/Card";
import CenterWrapper from "components/elements/CenterWrapper";
import MoreInfo from "components/elements/MoreInfo";
import PageTitle from "components/elements/PageTitle";
import PriceField from "components/elements/PriceField";
import Radio from "components/elements/Radio";
import RadioGroup from "components/elements/RadioGroup";
import ResponsiveLabel from "components/elements/ResponsiveLabel";
import Select from "components/elements/Select";
import TooltipFreeUserUpsell from "components/elements/TooltipFreeUserUpsell";
import { Diamond } from "components/icons";
import SubscriptionLimitMsg from "components/modules/BotsList/SubscriptionLimitMsg";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  BotStrategyType,
  DcaBotMode,
  DcaBuyStrategyType,
  DcaDynamicStrategyType,
  ExchangeType,
  Side,
  SubscriptionType,
} from "types/enums";
import { getExchangeConnectionLabel, renderNumber } from "utils/formatter";
import { ConditionalWrapper, getOrderTypeByBotStrategyType, isEmptyArray, splitCurrencyPair } from "utils/index";

import DynamicStrategyRadio from "./DynamicStrategyRadio";
import DynamicStrategySection from "./DynamicStrategySection";
import DynamicStrategySelect from "./DynamicStrategySelect";
import FrequencyRadio from "./FrequencyRadio";
import LimitOrdersSection from "./LimitOrdersSection";
import useNewDcaBotData from "./module/useNewDcaBotData";

const NewDcaBot = () => {
  const { id } = useParams();
  const { t } = useTranslation<string>();
  const theme = useTheme();
  const { subscriptionType } = useUserDataContext();
  const { colors } = useMUIThemeModeContext();
  const {
    userExchangeConnections,
    availableBalance,
    limitOrdersSum,
    selectedExchange,
    exchangesCurrencyPair,
    exchangeConnectionSelectProps,
    currencyPairSelectProps,
    labelFieldProps,
    savingsTargetFieldProps,
    formik,
    handleFrequencyChange,
    isValidating,
    dcaBotsData,
    isProcessing,
    askPrice,
    initialRunVisible,
    initialRunEnabled,
    initialRunProps,
    currentDynamicStrategyMultiplier,
    dynamicStrategyChart,
    hasLimitDynamicAlert,
    hasEditedLimitOrders,
    isDefaultValuesTransition,
  } = useNewDcaBotData(id);
  const { baseCurrency, counterCurrency } = splitCurrencyPair(formik.values.currencyPair);
  const { counterCurrDisplayedScale, currencyPairBaseScale, getLimit } = useExchangesMetadata(
    selectedExchange,
    formik.values.currencyPair
  );
  const selectedExchangeObject = userExchangeConnections?.exchangeConnections.filter(
    (item) => item.id === exchangeConnectionSelectProps.value
  )[0];
  const selectedExchangeTitle = selectedExchangeObject?.exchangeTitle;

  const getNumOfLimitOrders = () => {
    if (subscriptionType) {
      return subscriptionLimitOrders[subscriptionType];
    }

    return subscriptionLimitOrders["FREE"];
  };

  const getPeriodForLimitOrders = () => {
    if (formik.values.frequency === "D") return `${formik.values.frequencyParameter}${t("dcaBots.create.limitOrder.daily")}`;
    if (formik.values.frequency === "W") return t("dcaBots.create.limitOrder.weekly");
    if (formik.values.frequency === "M") return t("dcaBots.create.limitOrder.monthly");

    return "";
  };

  const changeWithValidate = (e: any) => {
    formik.setFieldTouched(e.target.id);
    formik.handleChange(e);
  };

  const hasLimitOrdersWithDuplicatedPercentChange = () => {
    const arr = formik.values.limitOrders.map((item) => item.percentChange);
    return arr.length !== new Set(arr).size;
  };

  const canAddNewBot = () =>
    !(subscriptionType && dcaBotsData && subscriptionDcaBots[subscriptionType] <= dcaBotsData.dcaBots.length);

  const isSubmitButtonDisabled = () => {
    return (!id && !canAddNewBot()) || hasLimitOrdersWithDuplicatedPercentChange();
  };

  const getMinAmountInfo = () => {
    const orderType = getOrderTypeByBotStrategyType(formik.values.strategyType);
    const minAmount = getLimit(Side.BUY, "min", "base", formik.values.currencyPair, selectedExchange, orderType);
    if (askPrice && minAmount) {
      return (
        <Grid item xs={12}>
          <Alert severity="warning">
            <Stack direction="column" spacing={0.5}>
              <Box>
                <Trans
                  i18nKey="dcaBots.create.alert.minAmount1"
                  components={{
                    strong: <strong />,
                  }}
                  values={{
                    minAmountBaseCurrency: `${renderNumber(minAmount, currencyPairBaseScale)}\u00a0${baseCurrency}`,
                    minAmountCounterCurrency: `${renderNumber(
                      minAmount * askPrice,
                      counterCurrDisplayedScale,
                      true
                    )}\u00a0${counterCurrency}`,
                    price: `${renderNumber(askPrice)}\u00a0${counterCurrency}`,
                    baseCurrency,
                  }}
                />
              </Box>
              <Box>
                <Trans i18nKey="dcaBots.create.alert.minAmount2" />
              </Box>
            </Stack>
          </Alert>
        </Grid>
      );
    }
    return undefined;
  };

  const canCreateDynamicStrategy = () => {
    if (formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC) {
      return subscriptionType === SubscriptionType.PREMIUM && formik.values.dynamicStrategyType !== DcaDynamicStrategyType.EMPTY;
    }

    return true;
  };

  const canRenderDynamicStrategySection = () => formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC;

  const canRenderOrderValue = (type: BotStrategyType, inSection?: boolean) => {
    if (formik.values.buyStrategyType === DcaBuyStrategyType.FIXED) {
      return formik.values.strategyType === type;
    }

    return formik.values.strategyType === type && inSection;
  };

  const getDynamicStrategyMultiplierInfo = (value?: number | string) => {
    if (currentDynamicStrategyMultiplier && value && formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC) {
      const currentValue = Number(`${value}`.replace(",", "."));
      const minMultiplier = formik.values.dynamicStrategySettings.minMultiplier;
      const maxMultiplier = formik.values.dynamicStrategySettings.maxMultiplier;

      const from = `${renderNumber(
        currentValue * minMultiplier,
        counterCurrDisplayedScale,
        true
      )}\u00a0${counterCurrency}`;
      const to = `${renderNumber(
        currentValue * maxMultiplier,
        counterCurrDisplayedScale,
        true
      )}\u00a0${counterCurrency}`;
      const current = `${renderNumber(
        currentValue * currentDynamicStrategyMultiplier,
        counterCurrDisplayedScale,
        true
      )}\u00a0${counterCurrency}`;
      const multiplier = `${renderNumber(currentDynamicStrategyMultiplier, 2)}`;

      const minMultiplierString = `${minMultiplier}`.replace(".", ",");
      const maxMultiplierString = `${maxMultiplier}`.replace(".", ",");

      return (
        <Trans
          i18nKey="dcaBots.create.dynamicStrategy.multiplierInfo"
          values={{
            minMultiplierString,
            from,
            maxMultiplierString,
            to,
            multiplier,
            current,
          }}
        />
      );
    }
  };

  const getOrderValueLabel = (label: string) => {
    if (formik.values.buyStrategyType === DcaBuyStrategyType.DYNAMIC) {
      return t(`dcaBots.create.dynamicStrategyOrderValue.${formik.values.mode}`);
    }

    return label;
  };

  const orderValueSection = (inSection?: boolean) => (
    <>
      {canRenderOrderValue(BotStrategyType.MARKET, inSection) && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
              {t("dcaBots.create.marketOrderTitle")}
            </Typography>
          </Grid>
          {getMinAmountInfo()}
          <Grid item xs={12}>
            <PriceField
              fullWidth={true}
              autoComplete="off"
              onlyPositive
              allowZero
              precision={counterCurrDisplayedScale}
              id={"marketOrderValue"}
              name={"marketOrderValue"}
              label={getOrderValueLabel(t(`dcaBots.create.marketOrderValue.${formik.values.mode}`))}
              value={formik.values.marketOrderValue ?? ""}
              onChange={changeWithValidate}
              onBlur={formik.handleBlur}
              error={formik.touched.marketOrderValue && Boolean(formik.errors.marketOrderValue)}
              helperText={
                (formik.touched.marketOrderValue && formik.errors.marketOrderValue)
                || getDynamicStrategyMultiplierInfo(formik.values.marketOrderValue)
              }
              disabled={!Boolean(formik.values.currencyPair)}
              InputProps={{
                endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
              }}
            />
          </Grid>
        </>
      )}
      {canRenderOrderValue(BotStrategyType.LIMIT, inSection) && (
        <>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
              {t("dcaBots.create.limitOrder.title")}
            </Typography>
          </Grid>
          {getMinAmountInfo()}
          <LimitOrdersSection
            limitOrders={formik.values.limitOrders ?? []}
            numOfOrders={getNumOfLimitOrders()}
            setFieldValue={formik.setFieldValue}
            onChange={changeWithValidate}
            onBlur={formik.handleBlur}
            errors={formik.errors}
            counterCurrency={counterCurrency}
            period={getPeriodForLimitOrders()}
            touched={formik.touched}
            disabled={!Boolean(formik.values.currencyPair)}
            counterCurrDisplayedScale={counterCurrDisplayedScale}
            dynamicStrategyMultiplierInfo={getDynamicStrategyMultiplierInfo}
            label={getOrderValueLabel(t(`dcaBots.create.limitOrder.orderValue.${formik.values.mode}`))}
            mode={formik.values.mode as DcaBotMode}
          />
        </>
      )}
    </>
  );

  const getInitRun = (
    key: "update" | "create",
    descriptionValues?: object,
    checkboxValues?: { value: string; notEnoughCounter?: string; disabled?: boolean }
  ) => (
    <>
      <Grid item xs={12} sx={{ mt: 5 }}>
        <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
          {t(`dcaBots.create.initialRun.title.${key}`)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" component="h3" sx={{ mt: 1 }}>
          <Trans i18nKey={`dcaBots.create.initialRun.description.${key}`} values={{ ...descriptionValues }} />
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <FormControlLabel
          control={<Checkbox {...initialRunProps} />}
          label={
            <Trans
              i18nKey={`dcaBots.create.initialRun.checkboxLabel.${key}`}
              values={{
                value: checkboxValues?.value,
                notEnoughCounter: checkboxValues?.notEnoughCounter,
              }}
            />
          }
          disabled={checkboxValues?.disabled}
        />
      </Grid>
    </>
  );

  const hasErrors = Object.keys(formik.touched).some((key) => formik.touched[key as keyof typeof formik.touched] && Boolean(formik.errors[key as keyof typeof formik.errors]));

  const canRenderMainSettings = () => formik.values.mode && formik.values.mode !== DcaBotMode.MANUAL;

  const getDynamicStrategySection = () => {
    if (!formik.values.dynamicStrategyType || formik.values.dynamicStrategyType === DcaDynamicStrategyType.EMPTY) return;

    if (isDefaultValuesTransition) {
      return (
        <CenterWrapper>
          <CircularProgress />
        </CenterWrapper>
      );
    }

    return (
      <DynamicStrategySection
        type={formik.values.dynamicStrategyType}
        setFieldValue={formik.setFieldValue}
        minMultiplier={formik.values.dynamicStrategySettings.minMultiplier}
        maxMultiplier={formik.values.dynamicStrategySettings.maxMultiplier}
        top={formik.values.dynamicStrategySettings.top}
        bottom={formik.values.dynamicStrategySettings.bottom}
        center={formik.values.dynamicStrategySettings.center}
        mode={formik.values.mode as DcaBotMode}
        dynamicStrategyChart={dynamicStrategyChart}>
        {orderValueSection(true)}
      </DynamicStrategySection>
    );
  };

  return (
    <Card header={id ? t("dcaBots.create.headerUpdate") : t("dcaBots.create.header")} maxWidth="sm" sx={{ mx: "auto" }}>
      <BackdropLoading open={!!id && isValidating} />
      <PageTitle i18nKey={id ? "dcaBots.create.meta_title_update" : "dcaBots.create.meta_title"} />
      {!id && !canAddNewBot() && (
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Alert severity="warning" sx={{ mb: 4 }}>
              <SubscriptionLimitMsg id={"dcaBots"} subscriptionType={subscriptionType!} />
            </Alert>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
            {t("dcaBots.create.modeTitle")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" fontWeight={400}>
            <Trans i18nKey="dcaBots.create.modeDescription" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            id={"mode"}
            name={"mode"}
            onChange={formik.handleChange}
            value={formik.values.mode}
            error={formik.errors.mode}>
            <Radio value={DcaBotMode.DCA_BUY} label={`${t("dcaBots.create.mode.DCA_BUY")}`} />
            <Radio value={DcaBotMode.DCA_SELL} label={`${t("dcaBots.create.mode.DCA_SELL")}`} />
            <Radio value={DcaBotMode.COMBO} label={`${t("dcaBots.create.mode.COMBO")}`} disabled={true} />
            <Radio value={DcaBotMode.MANUAL} label={`${t("dcaBots.create.mode.MANUAL")}`} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" fontWeight={400}>
            <Trans i18nKey="dcaBots.create.modeChangeDescription" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" fontWeight={400}>
            <Trans i18nKey="dcaBots.create.initialAmountsDescription" />
          </Typography>
        </Grid>
        {formik.values.mode && (
          <>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                {t("dcaBots.create.basicSettings")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Select {...exchangeConnectionSelectProps}>
                {userExchangeConnections?.exchangeConnections?.map((connection, index) => (
                  <MenuItem
                    key={`availableExchangeConnections-${index}`}
                    value={connection.id}
                    disabled={!connection.functional}>
                      {getExchangeConnectionLabel(connection.exchangeTitle, connection.functional, connection.label, " | ")}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {subscriptionType === "FREE"
              && formik.values.mode !== DcaBotMode.COMBO
              && (selectedExchangeObject?.exchangeEnum === ExchangeType.COINMATE
                || selectedExchangeObject?.exchangeEnum === ExchangeType.ANYCOIN) && (
                <Grid item xs={12}>
                  <Alert severity="info" sx={{ "@media (max-width:400px)": { fontSize: ".8rem" } }}>
                    <AlertTitle sx={{ "@media (max-width:400px)": { fontSize: ".8rem" } }}>
                      <Trans i18nKey={`dcaBots.create.alert.currencyPair.freeCzkEurInfoTitle.${formik.values.mode}`} />
                    </AlertTitle>
                    <Trans i18nKey={`dcaBots.create.alert.currencyPair.freeCzkEurInfo.${formik.values.mode}`} />
                  </Alert>
                </Grid>
              )}
            <Grid item xs={12}>
              <Select
                {...currencyPairSelectProps}
                disabled={
                  !exchangesCurrencyPair || isEmptyArray(exchangesCurrencyPair) || Boolean(id) || !subscriptionType
                }>
                {exchangesCurrencyPair.map((currencyPair, index: number) => {
                  return (
                    <MenuItem key={`exchangesCurrencyPair-${index}`} value={currencyPair.key}>
                      {currencyPair.key}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            {subscriptionType === "FREE" && (
              <Grid item xs={12}>
                <Alert severity="info" sx={{ "@media (max-width:400px)": { fontSize: ".8rem" } }}>
                  <Trans
                    i18nKey="dcaBots.create.alert.currencyPair.free"
                    components={{
                      a: <MUILink href={"/predplatne"} color={theme.palette.info.main} />,
                    }}
                  />
                </Alert>
              </Grid>
            )}
          </>
        )}
        {formik.values.currencyPair && (
          <>
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                {t("dcaBots.create.otherInformation")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField {...labelFieldProps} label={ResponsiveLabel(t("dcaBots.create.label"))} autoComplete="off" />
            </Grid>
            <Grid item xs={12}>
              <PriceField
                {...savingsTargetFieldProps}
                allowZero
                label={ResponsiveLabel(t("dcaBots.create.savingsTarget"))}
                autoComplete="off"
                onlyPositive
                precision={counterCurrDisplayedScale}
                InputProps={{
                  endAdornment: <InputAdornment position="start">{counterCurrency}</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" fontWeight={400}>
                <Trans i18nKey="dcaBots.create.otherInformationDescription" />
              </Typography>
            </Grid>
            {canRenderMainSettings() && (
              <>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                    {t("dcaBots.create.frequency.label")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    id={"frequency"}
                    name={"frequency"}
                    onChange={handleFrequencyChange}
                    value={formik.values.frequency}>
                    <FrequencyRadio
                      value="W"
                      subscriptionType={subscriptionType}
                      checked={formik.values.frequency === "W"}
                      label={`${t("dcaBots.create.frequency.weekly")}`}
                      onChangeSelect={formik.handleChange}
                      selectValue={formik.values.frequencyParameter}
                    />
                    <FrequencyRadio
                      value="M"
                      subscriptionType={subscriptionType}
                      checked={formik.values.frequency === "M"}
                      label={`${t("dcaBots.create.frequency.monthly")}`}
                      onChangeSelect={formik.handleChange}
                      selectValue={formik.values.frequencyParameter}
                    />
                    <FrequencyRadio
                      value="D"
                      subscriptionType={subscriptionType}
                      checked={formik.values.frequency === "D"}
                      label={
                        formik.values.frequency === "D"
                          ? `${t("dcaBots.create.frequency.custom.selected")}`
                          : `${t("dcaBots.create.frequency.custom.normal")}`
                      }
                      onChangeSelect={formik.handleChange}
                      selectValue={formik.values.frequencyParameter}
                      disabled={!subscriptionType || subscriptionType === "FREE"}
                    />
                  </RadioGroup>
                </Grid>
                {subscriptionType === "FREE" && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Trans
                        i18nKey="dcaBots.create.alert.frequency.free"
                        components={{
                          a: <MUILink color={theme.palette.info.main} href={"/predplatne"} />,
                        }}
                      />
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                    {t("dcaBots.create.strategyLabel")}
                  </Typography>
                  <MoreInfo>
                    <AspectRatioWrapper aspectRatio={"16 / 9"}>
                      <iframe
                        width="100%"
                        src="https://www.youtube-nocookie.com/embed/uuMalJpmHnM?autoplay=1&start=722"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        style={{ display: "block", aspectRatio: "16 / 9" }}
                      />
                    </AspectRatioWrapper>
                  </MoreInfo>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    id={"strategyType"}
                    name={"strategyType"}
                    onChange={formik.handleChange}
                    value={formik.values.strategyType}>
                    <Radio value={BotStrategyType.MARKET} label={t(`dcaBots.create.market.${formik.values.mode}`)} />
                    <Radio value={BotStrategyType.LIMIT} label={t(`dcaBots.create.limit.${formik.values.mode}`)} />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                    {t("dcaBots.create.buyStrategy")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    id={"buyStrategyType"}
                    name={"buyStrategyType"}
                    onChange={formik.handleChange}
                    value={formik.values.buyStrategyType}>
                    <Radio value={DcaBuyStrategyType.FIXED} label={`${t("dcaBots.create.fixedValue")}`} />
                    <DynamicStrategyRadio value={DcaBuyStrategyType.DYNAMIC} label={`${t("dcaBots.create.dynamicValue")}`} />
                  </RadioGroup>
                </Grid>
                {hasLimitDynamicAlert && (
                  <Grid item xs={12}>
                    <Alert severity="error" sx={{ mt: 1 }}>
                      <AlertTitle>{t("dcaBots.create.limitDynamicAlert.title")}</AlertTitle>
                      <Trans i18nKey="dcaBots.create.limitDynamicAlert.description" />
                    </Alert>
                  </Grid>
                )}
                {canRenderDynamicStrategySection() && !hasLimitDynamicAlert && (
                  <>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                        <Trans i18nKey={"dcaBots.create.dynamicValueInfoTitle"} />
                      </Typography>
                      <Typography color={"text.secondary"} variant="body2" sx={{ mt: 1 }}>
                        <Trans i18nKey={`dcaBots.create.dynamicValueInfoText.${formik.values.mode}`} />
                      </Typography>
                      <Alert severity={"success"} sx={{ mt: 2 }}>
                        <AlertTitle>
                          <Trans i18nKey={`dcaBots.create.dynamicValueInfoAlertTitle.${formik.values.mode}`} />
                        </AlertTitle>
                        <Stack direction="column" spacing={1}>
                          <Box>
                            <Trans i18nKey={`dcaBots.create.dynamicValueInfoAlertText1.${formik.values.mode}`} />
                          </Box>
                          <Box>
                            ➡️{" "}
                            <Trans
                              i18nKey={"dcaBots.create.dynamicValueInfoAlertText2"}
                              components={{
                                a: (
                                  <MUILink
                                    href={"/proc-bitcoin/dynamicka-dca-strategie"}
                                    target={"_blank"}
                                    color={"inherit"}
                                    fontWeight={"bold"}
                                  />
                                ),
                              }}
                            />
                          </Box>
                          {formik.values.mode === DcaBotMode.DCA_BUY && (
                            <Box>
                              ➡️{" "}
                              <Trans
                                i18nKey={"dcaBots.create.dynamicValueInfoAlertText3"}
                                components={{
                                  a: (
                                    <MUILink
                                      href={"/backtest-dca-strategie"}
                                      target={"_blank"}
                                      color={"inherit"}
                                      fontWeight={"bold"}
                                    />
                                  ),
                                }}
                              />
                            </Box>
                          )}
                        </Stack>
                      </Alert>
                      <Typography variant="h6" component="h2" fontWeight={400} sx={{ mt: 2, color: "tertiary.main" }}>
                        {t("dcaBots.create.dynamicStrategy.settings")}
                      </Typography>
                      <DynamicStrategySelect
                        onChangeSelect={formik.handleChange}
                        selectValue={formik.values.dynamicStrategyType}
                        exchangesCurrencyPair={exchangesCurrencyPair}
                        currencyPair={formik.values.currencyPair}
                        setFieldValue={formik.setFieldValue}
                        error={formik.touched.dynamicStrategyType && formik.errors.dynamicStrategyType}
                      />
                    </Grid>
                    {getDynamicStrategySection()}
                    {subscriptionType !== SubscriptionType.PREMIUM && (
                      <Grid item xs={12}>
                        <Alert
                          style={{ backgroundColor: colors.purple100, color: colors.purple500 }}
                          icon={<Diamond style={{ color: colors.purple500 }} />}>
                          <AlertTitle>
                            <Trans i18nKey={"dcaBots.create.dynamicStrategyLabel.upsell.title"} />
                          </AlertTitle>
                          <Trans
                            i18nKey={"dcaBots.create.dynamicStrategyLabel.upsell.description"}
                            components={{
                              a1: <MUILink href={"/predplatne"} color={"inherit"} fontWeight={"bold"} />,
                              a2: <MUILink href={"/mesicni-platba"} color={"inherit"} fontWeight={"bold"} />,
                            }}
                          />
                        </Alert>
                      </Grid>
                    )}
                  </>
                )}
                {orderValueSection()}
              </>
            )}
          </>
        )}
      </Grid>
      {canRenderMainSettings() && (
        <>
          {hasLimitOrdersWithDuplicatedPercentChange() && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t("dcaBots.create.limitOrder.errorDuplicatedPercentChange")}
            </Alert>
          )}
          {initialRunVisible
            && !hasLimitDynamicAlert
            && getInitRun(
              "create",
              {
                amount: `${renderNumber(availableBalance, counterCurrDisplayedScale)}\u00a0${counterCurrency}`,
                exchange: selectedExchangeTitle,
              },
              {
                value: `${renderNumber(limitOrdersSum, counterCurrDisplayedScale)}\u00a0${counterCurrency}`,
                notEnoughCounter: !initialRunEnabled ? `(${t("dcaBots.create.initialRun.notEnoughCounter")})` : undefined,
                disabled: !initialRunEnabled,
              }
            )}
          {hasEditedLimitOrders && !hasLimitDynamicAlert && formik.values.strategyType === BotStrategyType.LIMIT && getInitRun("update")}
        </>
      )}
      <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
        <Grid item>
          <ConditionalWrapper
            condition={!canCreateDynamicStrategy()}
            wrapper={(children) => <TooltipFreeUserUpsell>{children}</TooltipFreeUserUpsell>}>
            <Stack spacing={1} alignItems={"center"}>
              <Button
                variant="contained"
                disabled={
                  isSubmitButtonDisabled()
                  || isProcessing
                  || hasLimitDynamicAlert
                  || !canCreateDynamicStrategy()
                  || !formik.values.mode
                }
                onClick={() => formik.handleSubmit()}>
                {id ? t("update") : t("create")} {t("dcaBots.create.createUpdateButtonSuffix")}
              </Button>
              {hasErrors && (
                <Typography color={"error"} variant="caption" fontWeight={400}>
                  {t("formHasErrors")}
                </Typography>
              )}
            </Stack>
          </ConditionalWrapper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NewDcaBot;
