import { Box } from "@mui/material";
import { styled, alpha, Theme } from "@mui/material/styles";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

import { IColoredBoxProps } from "./types";

export const badgeColors = (theme: Theme) => {
  const { colors } = useMUIThemeModeContext();

  return {
    success: colors.success,
    error: colors.error,
    info: theme.palette.tertiary.main,
    default: theme.palette.text.primary,
    purple: colors.purple500,
  };
};

export const ColoredBox = styled(Box)<IColoredBoxProps>(({ customColor, theme, withTextTransform }) => {
  const availableColors = badgeColors(theme);

  return {
    backgroundColor: alpha(availableColors[customColor], 0.1),
    borderRadius: "10px",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: availableColors[customColor],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: withTextTransform ? "uppercase" : "none",
  };
});
