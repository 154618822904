import React from "react";

import { alpha, Box, Stack, Typography } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import { AccountingCoinsStack, BarChart, CoinsHand } from "components/icons";
import SatsView from "components/modules/SatsView";
import { useTranslation } from "react-i18next";

interface ReviewCardProps {
  type: "totalInvested" | "currentValue" | "currentAmount";
  color: string;
  amount: number;
  scale: number;
  currency: string;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ type, color, amount, currency, scale }) => {
  const { t } = useTranslation();

  const getIconByType = () => {
    switch (type) {
      case "totalInvested":
        return <AccountingCoinsStack sx={{ fontSize: 24, mr: 1 }} />;
      case "currentValue":
        return <BarChart sx={{ fontSize: 24, mr: 1 }} />;
      case "currentAmount":
        return <CoinsHand sx={{ fontSize: 24, mr: 1 }} />;
      default:
        return null;
    }
  };

  return (
    <Box
      bgcolor={alpha(color, 0.1)}
      borderRadius="10px"
      color={color}
      display="flex"
      alignItems="center"
      width={"100%"}
      px={{ xs: 2, md: 3 }}
      py={1}>
      <Stack>
        <Typography fontSize={"1rem"} fontWeight={600} sx={{ textTransform: "none" }}>
          {t(`exitStrategy.create.${type}`)}
        </Typography>
        <Stack width={"100%"} direction={"row"} alignItems={"center"}>
          {getIconByType()}
          <MaskedModeWrapper color={color} variant={"h6"}>
            <SatsView
              variant="h6"
              color={color}
              value={amount}
              withZeros={!!amount}
              scale={scale}
              currency={currency}
            />
          </MaskedModeWrapper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReviewCard;
