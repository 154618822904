import React from "react";

import { FormControl, FormHelperText, FormLabel, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface IProps {
  label?: string;
  value: string | string[];
  onChange: (event: React.MouseEvent<HTMLElement>, newAlignment: string | string[] | null) => void;
  items: { value: string; label: string }[];
  exclusive?: boolean;
  error?: string | boolean;
  fullWidth?: boolean;
  size?: "small" | "medium" | "large";
  color?: "primary" | "success" | "error" | "secondary" | "info" | "warning" | "standard";
  disabled?: boolean;
}

const FormToggleButton = ({ label, value, onChange, items, exclusive = true, error, fullWidth, size, color = "primary", disabled }: IProps) => {
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <ToggleButtonGroup
        color={color}
        fullWidth={fullWidth}
        size={size}
        value={value}
        exclusive={exclusive}
        disabled={disabled}
        onChange={onChange}>
        {items.map((item, index) => (
          <ToggleButton key={`${index}-${item.value}`} value={item.value}>
            {item.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default FormToggleButton;
