import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { alpha, Box, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { BarChart4, BarChart6, CoinsHandLarge } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans } from "react-i18next";
import { ExitStrategyType } from "types/enums";

interface TypeSelectProps {
  options: ExitStrategyType[];
  value: ExitStrategyType | null;
  onChange: (value: ExitStrategyType) => void;
  disabled: boolean;
  baseCurrency: string;
  counterCurrency: string;
}

const Item = styled(Box)<{ selected: boolean; disabled: boolean }>(({ selected, theme, disabled }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: "12px",
    border: `1.5px solid ${selected ? theme.palette.primary.main : alpha(colors["gray450"], 0.1)}`,
    height: "100%",
    cursor: disabled ? "not-allowed" : "pointer",
    opacity: disabled ? 0.6 : 1,
    pointerEvents: disabled ? "none" : "auto",
    transition: "border-color 0.3s ease",

    "&:hover": {
      borderColor: disabled ? "none" : theme.palette.primary.light,
    },
  };
});

const TypeSelect: React.FC<TypeSelectProps> = ({ options, value, onChange, disabled, baseCurrency, counterCurrency }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { colors } = useMUIThemeModeContext();

  const getIcon = (option: ExitStrategyType) => {
    switch (option) {
      case ExitStrategyType.LINEAR_BASE_CURR_SELL:
        return <BarChart6 style={{ fontSize: "32px" }} />;
      case ExitStrategyType.PCT_OF_GROWTH_SELL:
        return <BarChart4 style={{ fontSize: "32px" }} />;
      default:
        return <CoinsHandLarge />;
    }
  };

  return (
    <Grid container spacing={2}>
      {options.map((option) => (
        <Grid item key={option} xs={12} md={6} lg={4} height={"auto"}>
          <Item selected={option === value} onClick={() => onChange(option)} disabled={disabled}>
            {!isMobile && (
              <Stack direction={"row"} justifyContent={"space-between"} pb={3}>
                <Box
                  p={1.5}
                  borderRadius={"12.8px"}
                  bgcolor={alpha(colors["gray450"], 0.1)}
                  display={"flex"}
                  alignItems={"center"}>
                  {getIcon(option)}
                </Box>
                {option === value && <CheckCircleIcon color="primary" />}
              </Stack>
            )}
            <Stack spacing={2}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"1.125rem"} fontWeight={500}>
                  <Trans i18nKey={`exitStrategy.create.types.${option}.label`} values={{ baseCurrency, counterCurrency }} />
                </Typography>
                {option === value && isMobile && <CheckCircleIcon color="primary" />}
              </Stack>
              <Typography fontSize={"0.875rem"} color={colors["gray500"]}>
                <Trans i18nKey={`exitStrategy.create.types.${option}.description`} values={{ baseCurrency, counterCurrency }} />
              </Typography>
            </Stack>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

export default TypeSelect;
