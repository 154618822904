import {
  AFFILIATE,
  DISCORD,
  EMAIL,
  EXIT_STRATEGY,
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  YOUTUBE,
} from "constants/links";

import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Grid,
  Link as MUILink,
  Typography,
  Container,
  Divider,
  styled,
  Stack,
  useMediaQuery,
  useTheme,
  alpha,
} from "@mui/material";
import { ReactComponent as DarkLogo } from "assets/dark-logo.svg";
import { ReactComponent as Logo } from "assets/logo.svg";
import { Discord } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useIsLogin } from "hooks/useUser";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getComponentByMode } from "utils";

interface IFooterLinkProps {
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  to?: string;
}

const FooterLink = styled(({ to, href, target, ...props }: IFooterLinkProps) => {
  if (to) return <Link to={to} style={{ textDecoration: "none", color: "inherit" }} {...props} />;

  return <MUILink underline="none" color="inherit" display="block" href={href} target={target} {...props} />;
})(() => ({
  fontSize: "1rem",
  fontWeight: 300,
  lineHeight: "140%",
}));

const LAYOUT = {
  MAIN: {
    logo: {
      xs: 12,
      md: 12,
      lg: 2,
      order: { xs: 1, md: 5, lg: 1 },
    },
    guide: {
      xs: 12,
      md: 6,
      lg: 3,
      order: { xs: 2, md: 1, lg: 2 },
    },
    backtest: {
      xs: 12,
      md: 6,
      lg: 3,
      order: { xs: 3, md: 2, lg: 3 },
    },
    nextInfo: {
      xs: 12,
      md: 6,
      lg: 2,
      order: { xs: 4, md: 3, lg: 4 },
    },
    social: {
      xs: 12,
      md: 6,
      lg: 2,
      order: { xs: 5, md: 4, lg: 5 },
    },
  },
  DEFAULT: {
    logo: {
      xs: 12,
      sm: 12,
      md: 2.5,
      order: { xs: 1, sm: 5, md: 1 },
    },
    guide: {
      xs: 12,
      sm: 6,
      md: 3,
      order: { xs: 2, sm: 1, md: 2 },
    },
    backtest: {
      xs: 12,
      sm: 6,
      md: 2.5,
      order: { xs: 3, sm: 2, md: 3 },
    },
    nextInfo: {
      xs: 12,
      sm: 6,
      md: 2,
      order: { xs: 4, sm: 3, md: 4 },
    },
    social: {
      xs: 12,
      sm: 6,
      md: 2,
      order: { xs: 5, sm: 4, md: 5 },
    },
  },
};

const LOGO_DISPLAY = {
  MAIN: {
    BOX: {
      xs: "none",
      md: "block",
      lg: "none",
    },
    GRID: {
      xs: "block",
      md: "none",
      lg: "block",
    },
  },
  DEFAULT: {
    BOX: {
      xs: "none",
      sm: "block",
      md: "none",
    },
    GRID: {
      xs: "block",
      sm: "none",
      md: "block",
    },
  },
};

interface IProps {
  drawerOpen?: boolean;
  isHome: boolean;
}

const Footer: React.FC<IProps> = ({ isHome, drawerOpen }) => {
  const isLogin = useIsLogin();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const layout = LAYOUT[!isHome && drawerOpen ? "MAIN" : "DEFAULT"];
  const logoDisplay = LOGO_DISPLAY[!isHome && drawerOpen ? "MAIN" : "DEFAULT"];

  const socialMobileSection = (
    <Box bgcolor={colors["white"]} display={"flex"} alignItems={"center"} flexDirection={"column"} p={3} borderRadius={"24px"}>
      <Typography fontWeight={600} fontSize={"1rem"} textAlign={"left"} width={"100%"}>
        <MUILink href={`mailto:${EMAIL}`} underline="none" color={colors["blueGray"]}>
          {EMAIL}
        </MUILink>
      </Typography>
      <Stack pt={3} direction={"row"} justifyContent={"space-between"} width={"100%"}>
        <MUILink href={DISCORD} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <Discord height={24} width={24} style={{ width: 24, height: 24 }} />
        </MUILink>
        <MUILink href={TWITTER} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <XIcon fontSize="medium" />
        </MUILink>
        <MUILink href={INSTAGRAM} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <InstagramIcon fontSize="medium" />
        </MUILink>
        <MUILink href={FACEBOOK} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <FacebookIcon fontSize="medium" />
        </MUILink>
        <MUILink href={YOUTUBE} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <YouTubeIcon fontSize="medium" />
        </MUILink>
        <MUILink href={LINKEDIN} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <LinkedInIcon fontSize="medium" />
        </MUILink>
      </Stack>
    </Box>
  );

  const socialSection = (
    <Box bgcolor={colors["white"]} display={"flex"} alignItems={"center"} flexDirection={"column"} p={3} borderRadius={"24px"}>
      <Typography fontWeight={600} fontSize={"1rem"}>
        <MUILink href={`mailto:${EMAIL}`} underline="none" color={colors["blueGray"]}>
          {EMAIL}
        </MUILink>
      </Typography>
      <Stack pt={3} direction={"row"} justifyContent={"space-around"} width={"100%"}>
        <MUILink href={DISCORD} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <Discord height={24} width={24} style={{ width: 24, height: 24 }} />
        </MUILink>
        <MUILink href={TWITTER} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <XIcon fontSize="medium" />
        </MUILink>
        <MUILink href={INSTAGRAM} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <InstagramIcon fontSize="medium" />
        </MUILink>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-around"} width={"100%"}>
        <MUILink href={FACEBOOK} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <FacebookIcon fontSize="medium" />
        </MUILink>
        <MUILink href={YOUTUBE} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <YouTubeIcon fontSize="medium" />
        </MUILink>
        <MUILink href={LINKEDIN} target="_blank" rel="noreferrer" color={colors["silverGray"]}>
          <LinkedInIcon fontSize="medium" />
        </MUILink>
      </Stack>
    </Box>
  );

  const getSocialSection = () => {
    if ((isHome && isMobile) || (isSm && drawerOpen)) return socialMobileSection;

    return socialSection;
  };

  const getLogoItem = () => {
    return (
      <Link to={isLogin ? "/dashboard" : "/"} style={{ display: "flex" }}>
        {getComponentByMode(theme, <Logo height={"33"} />, <DarkLogo width={130} height={"33"} />)}
      </Link>
    );
  };

  return (
    <Box
      sx={{
        bgcolor: colors["lightGray"],
        color: colors["blueGray"],
        pt: { xs: 4, md: 9 },
        pb: { xs: 12, md: 8 },
        mt: isHome ? 0 : "auto",
        position: "relative",
      }}>
      <Container>
        <Grid container rowGap={4} justifyContent="space-between">
          <Grid item {...layout.logo} display={logoDisplay.GRID}>
            {getLogoItem()}
          </Grid>
          <Grid item {...layout.guide}>
            <Typography fontWeight={600} fontSize={"0.875rem"} lineHeight={"140%"}>
              {t("footer.guide.title")}
            </Typography>
            <Stack pt={2} spacing={{ xs: 0.5, md: 1.5 }}>
              <FooterLink to="/proc-bitcoin/stosuj-cz-navod-krok-za-krokem">{t("footer.guide.stepByStep")}</FooterLink>
              <FooterLink to="/proc-bitcoin/cashback-saty">{t("footer.guide.cashbackSats")}{" 🧡"}</FooterLink>
              <FooterLink to="/proc-bitcoin/dynamicka-dca-strategie">{t("footer.guide.dynamicDca")}</FooterLink>
              <FooterLink to="/proc-bitcoin/predstaveni-grid-strategie">
                {t("footer.guide.introductionGrid")}
              </FooterLink>
              <FooterLink to="/proc-bitcoin/detailni-vysvetleni-pouziti-a-fungovani-grid-strategie">
                {t("footer.guide.detailGrid")}
              </FooterLink>
              <FooterLink to="/proc-bitcoin/exit-strategie">{t("footer.guide.exitStrategy")}</FooterLink>
            </Stack>
          </Grid>
          <Grid item {...layout.backtest}>
            <Typography fontWeight={600} fontSize={"0.875rem"} lineHeight={"140%"}>
              {t("footer.backtest.title")}
            </Typography>
            <Stack pt={2} spacing={{ xs: 0.5, md: 1.5 }}>
              <FooterLink to="/#dca-kalkulacka">{t("footer.backtest.dcaCalc")}</FooterLink>
              <FooterLink to="/backtest-dca-strategie">{t("footer.backtest.dca")}</FooterLink>
              <FooterLink to="/backtest-grid-strategie">{t("footer.backtest.grid")}</FooterLink>
              <FooterLink href={EXIT_STRATEGY} target="_blank">
                {t("footer.backtest.exit")}
              </FooterLink>
            </Stack>
          </Grid>
          <Grid item {...layout.nextInfo}>
            <Typography fontWeight={600} fontSize={"0.875rem"} lineHeight={"140%"}>
              {t("footer.nextInfo.title")}
            </Typography>
            <Stack pt={2} spacing={{ xs: 0.5, md: 1.5 }}>
              <FooterLink to="/cenik">{t("footer.nextInfo.pricing")}</FooterLink>
              <FooterLink to="/menove-pary">{t("footer.nextInfo.currencyPairs")}</FooterLink>
              <FooterLink to="/#kontakt">{t("footer.nextInfo.consultation")}</FooterLink>
              <FooterLink to="/#reference">{t("footer.nextInfo.references")}</FooterLink>
              <FooterLink href={AFFILIATE} target="_blank">
                {t("footer.nextInfo.affiliate")}
              </FooterLink>
              <FooterLink to="/podminky">{t("footer.nextInfo.terms")}</FooterLink>
            </Stack>
          </Grid>
          <Grid item {...layout.social}>
            {getSocialSection()}
            <Box pt={4} display={logoDisplay.BOX}>
              {getLogoItem()}
            </Box>
          </Grid>
        </Grid>
        {isHome && (
          <>
            <Box py={{ xs: 4, md: 8 }}>
              <Divider sx={{ borderColor: alpha("#111827", 0.1) }} />
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <a href="https://web.thepay.cz/" target="_blank" rel="noopener">
                <img
                  width={"100%"}
                  style={{ maxWidth: "260px" }}
                  src="https://gate.thepay.cz/img/thepay-v2-220209-bw-gpay.svg?pid=1607"
                />
              </a>
            </Box>
          </>
        )}
      </Container>
    </Box>
  );
};

export default Footer;
