import { ForwardedRef, forwardRef, useMemo } from "react";

import { Stack, Typography, Checkbox, CheckboxProps, alpha, Alert, AlertTitle } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  GridColumnHeaderParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import Tooltip from "components/elements/Tooltip";
import { CheckIcon, CheckIconBorder, CheckIconDisabled, QuestionMark2, Sell } from "components/icons";
import { fontWeight } from "config/Theme/options";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangeRate from "hooks/useExchangeRate";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { SellOrderStatus } from "types/enums";
import { ISellOrders, ISellOrdersRow } from "types/types";
import { splitCurrencyPair } from "utils";
import { renderNumber } from "utils/formatter";

const CustomCheckbox = forwardRef((props: CheckboxProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <Checkbox
      {...props}
      ref={ref}
      icon={props.disabled ? <CheckIconDisabled /> : <CheckIconBorder />}
      checkedIcon={<CheckIcon />}
      sx={{
        "&.Mui-disabled": {
          opacity: 0.6,
        },
      }}
    />
  );
});

const CustomNoRowsOverlay = () => {
  const { t } = useTranslation();

  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {t("exitStrategy.create.preview.table.noRows")}
    </Stack>
  );
};

export const TableHeaderWithTooltip = (values: object, tooltip: string) => (params: GridColumnHeaderParams<any>) => {
  const { colors } = useMUIThemeModeContext();

  return (
    <>
      <Typography fontSize={"0.75rem"}>
        <Trans i18nKey={params.colDef.headerName} values={values} />
      </Typography>
      <Tooltip title={tooltip} placement="top">
        <Box
          ml={0.5}
          width={14}
          height={14}
          bgcolor={alpha(colors["gray450"], 0.1)}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          borderRadius="50%">
          <QuestionMark2 />
        </Box>
      </Tooltip>
    </>
  );
};

interface IProps {
  data: ISellOrdersRow[];
  selectedSellOrders: number[];
  setSelectedSellOrders: (value: number[]) => void;
  exchangeTitle: string;
  currencyPair: string;
  exchangeEnum: string;
}

const Preview = ({
  data,
  exchangeTitle,
  currencyPair,
  exchangeEnum,
  selectedSellOrders,
  setSelectedSellOrders,
}: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { showInUSD } = useUserDataContext();

  const { getUSDOriginalPrice, hasRateForCurrency } = useExchangeRate();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale, currencyPairPriceScale, currencyPairBaseScale } = useExchangesMetadata(exchangeEnum, currencyPair);

  const getPriceCurrencyPair = () => {
    if (showInUSD && hasRateForCurrency(counterCurrency)) return `${baseCurrency}/USD`;

    return currencyPair;
  };

  const getValue = (value: number) => {
    if (showInUSD) {
      const inUSD = getUSDOriginalPrice(counterCurrency, value);
      return inUSD ?? value;
    }

    return value;
  };

  const columns = useMemo<GridColDef<ISellOrders>[]>(
    () => [
      {
        field: "status",
        headerName: "exitStrategy.create.preview.table.status",
        renderHeader: TableHeaderWithTooltip(
          { exchangeTitle, baseCurrency },
          `${t("exitStrategy.create.preview.table.statusTooltip")}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        valueFormatter: ({ value }: GridValueFormatterParams<any>) => t(`exitStrategy.create.preview.${value}`),
      },
      {
        field: "price",
        headerName: "exitStrategy.create.preview.table.price",
        renderHeader: TableHeaderWithTooltip(
          { currencyPair: getPriceCurrencyPair() },
          `${t("exitStrategy.create.preview.table.priceTooltip")}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Stack spacing={1} direction={"row"} alignItems={"center"}>
            <Sell sx={{ color: "#D92D20", fontSize: "1rem" }} />
            <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"}>
              {renderNumber(getValue(value), currencyPairPriceScale, true)}
            </Typography>
          </Stack>
        ),
      },
      {
        field: "amountSum",
        headerName: "exitStrategy.create.preview.table.amountSum",
        renderHeader: TableHeaderWithTooltip({ baseCurrency }, `${t("exitStrategy.create.preview.table.amountSumTooltip")}`),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 140,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={colors["error"]}>
            -{renderNumber(value, currencyPairBaseScale, true)}
          </Typography>
        ),
      },
      {
        field: "valueSum",
        headerName: "exitStrategy.create.preview.table.valueSum",
        renderHeader: TableHeaderWithTooltip(
          { counterCurrency },
          `${t("exitStrategy.create.preview.table.valueSumTooltip")}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 140,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={colors["success"]}>
            +{renderNumber(value, counterCurrDisplayedScale, true)}
          </Typography>
        ),
      },
      {
        field: "amount",
        headerName: "exitStrategy.create.preview.table.amount",
        renderHeader: TableHeaderWithTooltip({ baseCurrency }, `${t("exitStrategy.create.preview.table.amountTooltip")}`),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={colors["error"]}>
            -{renderNumber(value, currencyPairBaseScale, true)}
          </Typography>
        ),
      },
      {
        field: "value",
        headerName: "exitStrategy.create.preview.table.value",
        renderHeader: TableHeaderWithTooltip({ counterCurrency }, `${t("exitStrategy.create.preview.table.valueTooltip")}`),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"} color={colors["success"]}>
            +{renderNumber(value, counterCurrDisplayedScale, true)}
          </Typography>
        ),
      },
      {
        field: "remainingAmount",
        headerName: "exitStrategy.create.preview.table.remainingAmount",
        renderHeader: TableHeaderWithTooltip(
          { baseCurrency },
          `${t("exitStrategy.create.preview.table.remainingAmountTooltip")}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"}>
            {renderNumber(value, baseCurrDisplayedScale, true)}
          </Typography>
        ),
      },
      {
        field: "remainingValue",
        headerName: "exitStrategy.create.preview.table.remainingValue",
        renderHeader: TableHeaderWithTooltip(
          { baseCurrency, counterCurrency },
          `${t("exitStrategy.create.preview.table.remainingValueTooltip")}`
        ),
        disableColumnMenu: true,
        sortable: false,
        minWidth: 150,
        flex: 1,
        renderCell: ({ value }: GridRenderCellParams) => (
          <Typography fontFamily={"monospace"} fontWeight={fontWeight.semiBold} fontSize={"inherit"}>
            {renderNumber(value, counterCurrDisplayedScale, true)}
          </Typography>
        ),
      },
    ],
    [
      exchangeTitle,
      currencyPair,
      baseCurrency,
      counterCurrency,
      currencyPairPriceScale,
      currencyPairBaseScale,
      counterCurrDisplayedScale,
      baseCurrDisplayedScale,
      showInUSD,
      hasRateForCurrency,
    ]
  );

  const handleRowSelectionModelChange = (selection: GridRowSelectionModel) => {
    const newSelection = selection.map(Number);
    const maxNew = Math.max(...newSelection);

    const addedItems = newSelection.filter((num) => !selectedSellOrders.includes(num));
    const removedItems = selectedSellOrders.filter((num) => !newSelection.includes(num));

    if (addedItems.length > 1 || removedItems.length > 1) {
      setSelectedSellOrders(newSelection);
      return;
    }

    if (addedItems.length === 1) {
      const result = Array.from({ length: maxNew + 1 }, (_, i) => i);
      setSelectedSellOrders(result);
      return;
    }

    if (removedItems.length === 1) {
      const maxRemoved = Math.max(...removedItems, -1);
      const result = selectedSellOrders.filter((num) => num < maxRemoved);
      setSelectedSellOrders(result);
      return;
    }

    setSelectedSellOrders(selectedSellOrders);
  };

  const getRowSelectionModel = () => {
    if (data.length === 0) return [];

    return selectedSellOrders;
  };

  return (
    <Box pt={9}>
      <Stack spacing={3} pb={4}>
        <Typography fontWeight={fontWeight.semiBold} fontSize={"1.5rem"}>
          {t("exitStrategy.create.preview.title")}
        </Typography>
        <Stack spacing={2}>
          <Typography fontWeight={fontWeight.regular} fontSize={"1rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.preview.description1"
              values={{ exchangeTitle, baseCurrency }}
            />
          </Typography>
          <Typography fontWeight={fontWeight.regular} fontSize={"1rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.preview.description2"
              values={{ exchangeTitle, baseCurrency }}
            />
          </Typography>
          <Typography fontWeight={fontWeight.regular} fontSize={"1rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.preview.description3"
              values={{ exchangeTitle, baseCurrency }}
            />
          </Typography>
          <Typography fontWeight={fontWeight.regular} fontSize={"1rem"} color={colors["gray500"]}>
            <Trans
              i18nKey="exitStrategy.create.preview.description4"
              values={{ exchangeTitle, baseCurrency }}
            /> ⚠️
          </Typography>
        </Stack>
      </Stack>
      {!selectedSellOrders.length ? (
        <Alert severity="error" sx={{ mb: 1 }}>
          <AlertTitle>{t("exitStrategy.create.preview.alert.title")}</AlertTitle>
          <Trans
            i18nKey="exitStrategy.create.preview.alert.description"
            values={{ exchangeTitle, baseCurrency }}
          />
        </Alert>
      ) : null}
      <DataGrid
        rows={data}
        autoHeight
        columns={columns}
        isRowSelectable={(params: GridRowParams) =>
          params.id !== 0 && params.row.status !== SellOrderStatus.NOT_ENOUGH_BALANCE
        }
        checkboxSelection
        slots={{ baseCheckbox: CustomCheckbox, noRowsOverlay: CustomNoRowsOverlay }}
        rowSelectionModel={getRowSelectionModel()}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        pagination
        pageSizeOptions={[]}
        sx={{
          border: "none",
          borderBottom: `1px solid ${colors["tableBorder"]}`,
          "& .MuiDataGrid-columnHeaders": {
            borderBottom: `1px solid ${colors["tableBorder"]}`,
            color: colors["gray500"],
          },
          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${colors["tableBorder"]}`,
            fontWeight: 500,
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            },
          },
        }}
      />
    </Box>
  );
};

export default Preview;
