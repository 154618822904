import { useEffect } from "react";

import { Grid, CircularProgress, Alert, AlertTitle, Box, Typography } from "@mui/material";
import BalanceItem from "components/elements/BalanceItem";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useAPIDcaBotsBalances } from "hooks/useAPI";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { IBalance } from "types/types";

interface IProps {
  id: string;
  exchangeTitle: string;
  exchangeLabel?: string;
  exchangeEnum?: string;
  baseCurrency: string;
  counterCurrency: string;
  isBrokerage?: boolean;
  isExchangeEnabled?: boolean;
  functional?: boolean;
  bidPrice?: number;
  setIsBalanceError: (value: boolean) => void;
}

const Balance = ({
  id,
  exchangeTitle,
  exchangeLabel,
  exchangeEnum,
  baseCurrency,
  counterCurrency,
  isBrokerage,
  isExchangeEnabled,
  functional,
  bidPrice,
  setIsBalanceError,
}: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { data, error, isValidating } = useAPIDcaBotsBalances(id, isExchangeEnabled && functional);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(exchangeEnum ?? "", `${baseCurrency}/${counterCurrency}`);

  useEffect(() => {
    setIsBalanceError(!!error);
  }, [error,]);
  
  const getCounterCurrencyAmount = (value: number) => {
    if (!bidPrice) return;

    const amount = value * bidPrice;
    return amount ? { value: amount, currency: counterCurrency, scale: counterCurrDisplayedScale } : undefined;
  };

  const balanceItem = (
    balance: IBalance,
    label: string,
    decimalPlaces: number,
    withZeros = false,
    isFirstChild = false
  ) => {
    const { available, frozen, currency } = balance;

    return (
      <>
        <BalanceItem
          label={label}
          value={available}
          decimalPlaces={decimalPlaces}
          withZeros={withZeros}
          currency={currency}
          isFirstChild={isFirstChild}
          amount={isFirstChild ? getCounterCurrencyAmount(available) : undefined}
        />
        {frozen !== 0 && (
          <BalanceItem
            label={t("dcaBots.balances.frozen")}
            value={frozen}
            decimalPlaces={decimalPlaces}
            withZeros={withZeros}
            currency={currency}
            tooltip={t("dcaBots.balances.frozenDesc")}
            amount={isFirstChild ? getCounterCurrencyAmount(frozen) : undefined}
          />
        )}
      </>
    );
  };

  return (
    <Box p={1.5} bgcolor={colors.lightGray} borderRadius={"10px"}>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }} pb={2}>
          <Typography fontSize={"1rem"} fontWeight={600} color="tertiary.main">
            {isBrokerage
              ? t("dcaBots.brokerage", { exchange: exchangeTitle ?? "" })
              : t("dcaBots.exchange", { exchange: exchangeTitle ?? "" })}
          </Typography>
          <Typography variant="body1" fontWeight={500} color="secondary.main">
            {exchangeLabel ?? ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
          {data?.baseCurrencyBalance
            && balanceItem(
              data?.baseCurrencyBalance,
              isBrokerage
                ? t("dcaBots.balances.storedOnBrokerage", { exchange: exchangeTitle ?? "" })
                : t("dcaBots.balances.storedOnExchange", { exchange: exchangeTitle ?? "" }),
              baseCurrDisplayedScale,
              true,
              true
            )}
          {data?.counterCurrencyBalance
            && balanceItem(data?.counterCurrencyBalance, t("dcaBots.balances.available"), counterCurrDisplayedScale, true)}
          {isValidating && !data && (
            <Box display={"flex"} justifyContent={"center"}>
              <CircularProgress color="primary" />
            </Box>
          )}
          {error
            && (error?.response?.status === 502 ? (
              <Alert severity="warning">
                <AlertTitle>
                  <Trans i18nKey="dcaBots.balances.warning.title" />
                </AlertTitle>
                <Trans i18nKey="dcaBots.balances.warning.description" />
              </Alert>
            ) : (
              <Alert severity="error">
                <AlertTitle>
                  <Trans
                    i18nKey={
                      isBrokerage ? "dcaBots.balances.errorBrokerageTitle" : "dcaBots.balances.errorExchangeTitle"
                    }
                  />
                </AlertTitle>
                <Trans i18nKey="dcaBots.balances.errorConnectionText" />
              </Alert>
            ))}
          {!functional && (
            <Alert severity="error">
              <AlertTitle>
                <Trans
                  i18nKey={
                    isBrokerage
                      ? "exchangeConnections.functionalError.brokerage"
                      : "exchangeConnections.functionalError.exchange"
                  }
                />
              </AlertTitle>
              <Trans i18nKey="exchangeConnections.functionalError.description" />
            </Alert>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Balance;
