import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { alpha, Box, Grid, Stack, styled, Typography } from "@mui/material";
import { ReactComponent as ExitStrategyBuyBackNone } from "assets/images/exit-strategy-buy-back-none.svg";
import { ReactComponent as ExitStrategyBuyBackOnce } from "assets/images/exit-strategy-buy-back-once.svg";
import { ReactComponent as ExitStrategyBuyBackRepeat } from "assets/images/exit-strategy-buy-back-repeat.svg";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans } from "react-i18next";
import { BuyBackModeType } from "types/enums";

interface TypeSelectProps {
  value: BuyBackModeType;
  onChange: (value: BuyBackModeType) => void;
}

const Item = styled(Box)<{ selected: boolean }>(({ selected, theme }) => {
  const { colors } = useMUIThemeModeContext();

  return {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: "12px",
    border: `1.5px solid ${selected ? theme.palette.primary.main : alpha(colors["gray450"], 0.1)}`,
    height: "100%",
    cursor: "pointer",
    background: colors["white"],

    transition: "border-color 0.3s ease",
    "&:hover": {
      borderColor: theme.palette.primary.light,
    },
  };
});

const TypeSelect: React.FC<TypeSelectProps> = ({ value, onChange }) => {
  const { colors } = useMUIThemeModeContext();

  const getIconByType = (option: BuyBackModeType) => {
    switch (option) {
      case BuyBackModeType.NONE:
        return <ExitStrategyBuyBackNone style={{ width: "100%", maxHeight: "300px" }} />;
      case BuyBackModeType.ONCE:
        return <ExitStrategyBuyBackOnce style={{ width: "100%", maxHeight: "300px" }} />;
      case BuyBackModeType.REPEAT:
        return <ExitStrategyBuyBackRepeat style={{ width: "100%", maxHeight: "300px" }} />;
      default:
        return null;
    }
  };

  return (
    <Grid container spacing={2} pt={6}>
      {Object.values(BuyBackModeType).map((option) => (
        <Grid item key={option} xs={12} lg={4} height={"auto"}>
          <Item selected={option === value} onClick={() => onChange(option)}>
            <Stack spacing={2} height={"100%"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"1.125rem"} fontWeight={500}>
                  <Trans i18nKey={`exitStrategy.create.buyBack.types.${option}.label`} />
                </Typography>
                {option === value && <CheckCircleIcon color="primary" />}
              </Stack>
              <Typography fontSize={"0.875rem"} color={colors["gray500"]}>
                <Trans i18nKey={`exitStrategy.create.buyBack.types.${option}.description`} />
              </Typography>
              <Box display={"flex"} height={"100%"} alignItems={"flex-end"}>
                {getIconByType(option)}
              </Box>
            </Stack>
          </Item>
        </Grid>
      ))}
    </Grid>
  );
};

export default TypeSelect;
