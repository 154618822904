import { DISCORD } from "constants/links";

import React from "react";

import { Checkbox, FormControlLabel, Link, Stack, styled, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { CheckIcon, CheckIconBorder } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { Trans, useTranslation } from "react-i18next";

const CheckboxControlLabel = styled(FormControlLabel)(() => {
  const { colors } = useMUIThemeModeContext();

  return {
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
      color: colors["gray500"],
    },
  };
});

const StyledLink = styled(Link)(() => ({
  textDecoration: "underline",
}));

interface IProps {
  confirmations: boolean[];
  setConfirmations: (confirmations: boolean[]) => void;
}

const Confirmation = ({ confirmations, setConfirmations }: IProps) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();

  const handleChange = (index: number) => {
    const newConfirmations = [...confirmations];
    newConfirmations[index] = !newConfirmations[index];
    setConfirmations(newConfirmations);
  };

  return (
    <Box pt={6}>
      <Stack spacing={3} pb={4}>
        <Typography fontWeight={600} fontSize={"1.5rem"}>
          {t("exitStrategy.create.confirmation.title")}
        </Typography>
        <Typography fontWeight={400} fontSize={"1rem"} color={colors["gray500"]}>
          <Trans i18nKey="exitStrategy.create.confirmation.subtitle" />
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {confirmations.map((confirmation, index) => (
          <Box key={index} py={1.5} px={3} bgcolor={colors.lightGray} borderRadius={"12px"}>
            <CheckboxControlLabel
              control={
                <Checkbox
                  icon={<CheckIconBorder />}
                  checkedIcon={<CheckIcon />}
                  onChange={() => handleChange(index)}
                  checked={confirmation}
                />
              }
              label={
                <Typography pl={2}>
                  <Trans
                    i18nKey={`exitStrategy.create.confirmation.${index + 1}`}
                    components={{
                      aDiscord: <StyledLink href={DISCORD} target={"_blank"} />,
                      aExitFees: <StyledLink href={"/proc-bitcoin/exit-objednavky-a-poplatky"} target={"_blank"} />,
                    }}
                  />
                </Typography>
              }
            />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Confirmation;
