import { Stack, Typography } from "@mui/material";
import Card from "components/elements/Card";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import UsdWrapper, { TypographyItem } from "components/elements/UsdWrapper";
import { fontWeight } from "config/Theme/options";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { BuyBackModeType } from "types/enums";
import { IExitStrategyPctOfGrowthSellParams } from "types/types";
import { splitCurrencyPair } from "utils";
import { renderPrice } from "utils/formatter";

interface IProps {
  strategyParams: IExitStrategyPctOfGrowthSellParams;
  currencyPair: string;
  exchangeEnum: string;
}

const ExitStrategySettings = ({ strategyParams, currencyPair, exchangeEnum }: IProps) => {
  const { t } = useTranslation();
  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale } = useExchangesMetadata(exchangeEnum, currencyPair);

  return (
    <Card sx={{ mb: 0 }} header={t("dcaBots.exitStrategy.active")}>
      <Stack spacing={1}>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.type")}
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
            {t(`dcaBots.exitStrategy.types.${strategyParams.type}`)}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.totalAmount")}
          </Typography>
          <MaskedModeWrapper variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
            <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
              {renderPrice(strategyParams.totalAmount, baseCurrency, baseCurrDisplayedScale)}
            </Typography>
          </MaskedModeWrapper>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.firstSellingPrice")}
          </Typography>
          <UsdWrapper
            value={strategyParams.firstSellingPrice}
            counterCurrency={counterCurrency}
            baseCurrency={baseCurrency}>
            <TypographyItem
              variant="body2"
              color={"secondary"}
              fontWeight={fontWeight.semiBold}
              value={strategyParams.firstSellingPrice}
              scale={counterCurrDisplayedScale}
              currency={counterCurrency}
              withZeros
            />
          </UsdWrapper>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.highestSellingPricePct")}
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
            {`${strategyParams.highestSellingPricePct}\u00a0%`}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.sellingPriceStepPct")}
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
            {`${strategyParams.sellingPriceStepPct}\u00a0%`}
          </Typography>
        </Stack>
        {strategyParams.pctOfGrowthToSell && (
          <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
            <Typography variant="body2" color={"secondary"}>
              {t("dcaBots.exitStrategy.pctOfGrowthToSell")}
            </Typography>
            <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
              {`${strategyParams.pctOfGrowthToSell}\u00a0%`}
            </Typography>
          </Stack>
        )}
        <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
          <Typography variant="body2" color={"secondary"}>
            {t("dcaBots.exitStrategy.buyBackMode")}
          </Typography>
          <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold} sx={{ textAlign: { xs: "left", sm: "right" } }}>
            {t(`dcaBots.exitStrategy.buyBackModes.${strategyParams.buyBackMode}`)}
          </Typography>
        </Stack>
        {strategyParams.buyBackMode !== BuyBackModeType.NONE && (
          <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
            <Typography variant="body2" color={"secondary"}>
              {t("dcaBots.exitStrategy.buyBackPriceStepPct")}
            </Typography>
            <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
              {`${strategyParams.buyBackPriceStepPct}\u00a0%`}
            </Typography>
          </Stack>
        )}
        {strategyParams.buyBackMode !== BuyBackModeType.NONE && (
          <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between">
            <Typography variant="body2" color={"secondary"}>
              {t("dcaBots.exitStrategy.buyBackValuePct")}
            </Typography>
            <Typography variant="body2" color={"secondary"} fontWeight={fontWeight.semiBold}>
              {`${strategyParams.buyBackValuePct}\u00a0%`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};

export default ExitStrategySettings;
