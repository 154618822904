import React from "react";

import { alpha, Box, Stack, Typography } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import Tooltip from "components/elements/Tooltip";
import { QuestionMark2 } from "components/icons";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";

interface TileProps {
  title: string;
  icon: JSX.Element;
  scale: number;
  currency?: string;
  value?: number;
  withPrefix?: boolean;
  tooltip?: string;
}

const Tile: React.FC<TileProps> = ({ title, icon, scale, currency, value = 0, withPrefix, tooltip }) => {
  const { colors } = useMUIThemeModeContext();
  const prefix = withPrefix ? value > 0 ? "+" : "" : "";

  return (
    <Box display={"flex"} flexDirection={"column"} minWidth={110}>
      <Box
        bgcolor={alpha(colors["gray450"], 0.1)}
        borderRadius={"10px"}
        display={"flex"}
        alignItems={"center"}
        width={"fit-content"}
        p={1}>
        {icon}
      </Box>
      <Stack mt={1.5} spacing={0.5} direction={"row"}>
        <Typography fontSize={"0.75rem"} color={colors["gray500"]}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip ?? ""} placement="top">
            <Box
              width={14}
              height={14}
              bgcolor={alpha(colors["gray450"], 0.1)}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius="50%">
              <QuestionMark2 />
            </Box>
          </Tooltip>
        )}
      </Stack>
      <MaskedModeWrapper fontWeight={500} fontSize={"0.875rem"}>
        <SatsView
          color={colors.black}
          fontWeight={500}
          fontSize={"0.875rem"}
          value={value}
          currency={currency ?? ""}
          scale={scale}
          prefix={prefix}
        />
      </MaskedModeWrapper>
    </Box>
  );
};

export default Tile;
