import { Box, Grid, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import { creditsPayments } from "API/calls";
import { ReactComponent as BtcLogo } from "assets/currencies/color/btc.svg";
import Stripe from "assets/images/stripe.png";
import ThePay from "assets/images/thepay.png";
import Modal from "components/elements/Modal";
import { useTranslation } from "react-i18next";
import { CreditsPaymentGateway } from "types/enums";

import IProps, { IPaymentBoxProps } from "./types";

export const PaymentBox = ({ children, onSelect, buttonText }: IPaymentBoxProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: "10px",
        p: { xs: 1, sm: 2 },
        height: "100%",
      }}>
      <Grid container height={"100%"}>
        <Grid item xs={4} sm={12} display="flex" alignItems={"center"} justifyContent="center">
          {children}
        </Grid>
        <Grid
          item
          xs={8}
          sm={12}
          display="flex"
          justifyContent={{ xs: "end", sm: "center" }}
          alignItems={{ xs: "center", sm: "end" }}>
          <Button
            sx={{ height: "fit-content", fontSize: { xs: "0.6rem", sm: "0.875rem" }, mt: { xs: 0, sm: 1 } }}
            variant="contained"
            onClick={onSelect}
            color="success">
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export const AVAILABLE_PAYMENT_METHODS = [
  {
    gateway: CreditsPaymentGateway.THE_PAY,
    image: <img src={ThePay} alt={"ThePay"} style={{ maxWidth: "100px", width: "100%", maxHeight: "56px" }} />,
    title: "creditsBuy.selectPaymentMethod.withTransfer",
  },
  {
    gateway: CreditsPaymentGateway.STRIPE,
    image: <img src={Stripe} alt={"Stripe"} style={{maxWidth: "100px", width: "100%", maxHeight: "56px"}} />,
    title: "creditsBuy.selectPaymentMethod.withCard",
  },
  {
    gateway: CreditsPaymentGateway.OPEN_NODE,
    image: <BtcLogo width={200} style={{ maxWidth: "100%", maxHeight: "56px" }} />,
    title: "creditsBuy.selectPaymentMethod.withBtc"
  },
];

const SelectPaymentMethodModal = ({ open, handleClose, creditsPaymentType }: IProps) => {
  const { t } = useTranslation();

  const onConfirm = async (gateway: CreditsPaymentGateway) => {
    const { data } = await creditsPayments(creditsPaymentType, gateway);
    window.location.replace(data?.redirectUrl);
    handleClose();
  };

  // logic to pay for CREDITS_HALF_10000 package only via bitcoin
  const filteredPaymentMethods = creditsPaymentType === "CREDITS_HALF_10000"
      ? [AVAILABLE_PAYMENT_METHODS[AVAILABLE_PAYMENT_METHODS.length - 1]]
      : AVAILABLE_PAYMENT_METHODS;

  return (
    <Modal open={open} onCancel={handleClose} title={t("creditsBuy.selectPaymentMethod.title")} withCloseButton disableCancelButton>
      <Grid container spacing={{ xs: 0.5, sm: 1, md: 2 }}>
          {filteredPaymentMethods.map(({ gateway, image, title }) => (
          <Grid item xs={12} sm={4} md={4} key={gateway}>
            <PaymentBox onSelect={() => onConfirm(gateway)} buttonText={t(title)}>
              {image}
            </PaymentBox>
            </Grid>
        ))}
      </Grid>
    </Modal>
  );
};

export default SelectPaymentMethodModal;
