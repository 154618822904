import React, { useState } from "react";

import { Box, Button, Divider, Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import BackdropLoading from "components/elements/BackdropLoading";
import Card from "components/elements/Card";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { FormikProvider } from "formik";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { Trans, useTranslation } from "react-i18next";
import { ExitStrategyType, SellOrderStatus } from "types/enums";
import { IDcaBotDetail } from "types/types";
import { splitCurrencyPair } from "utils";

import Chart from "../DcaBotDetail/Detail/Chart";
import InitialValuesModal from "../DcaBotDetail/InitialValuesModal";
import Balance from "./Balance";
import BuyBack from "./BuyBack";
import Confirmation from "./Confirmation";
import useExitStrategyData from "./module/useExitStrategyData";
import Preview from "./Preview";
import ReviewCard from "./ReviewCard";
import Settings from "./Settings";
import TypeSelect from "./TypeSelect";

interface IProps {
  id: string;
  data: IDcaBotDetail;
  enabledExitStrategies: ExitStrategyType[];
  refreshData: () => void;
}

const NUMBER_OF_CONFIRMATIONS = 5;

const ExitStrategie: React.FC<IProps> = ({ id, data, enabledExitStrategies, refreshData }) => {
  const theme = useTheme();
  const { colors } = useMUIThemeModeContext();
  const { t } = useTranslation();
  const [initialValuesModalOpen, setInitialValuesModalOpen] = useState<boolean>(false);
  const [isBalanceError, setIsBalanceError] = useState<boolean>(false);
  const [confirmations, setConfirmations] = useState<boolean[]>(Array(NUMBER_OF_CONFIRMATIONS).fill(false));

  const { exchangeConnection, currencyPair, initialBaseCurrencyAmount, initialCounterCurrencyAmount, savingsTarget } = data;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const details = data.details!;

  const { baseCurrency, counterCurrency } = splitCurrencyPair(currencyPair);
  const { counterCurrDisplayedScale, baseCurrDisplayedScale, currencyPairPriceScale } = useExchangesMetadata(exchangeConnection.exchangeEnum, currencyPair);
  const { formik, previewData, isProcessing, sellOrders, selectedSellOrders, setSelectedSellOrders }
    = useExitStrategyData({
      details,
      baseCurrency,
      counterCurrency,
      currencyPairPriceScale,
      id,
    });

  const getcurrentValueCardColor = () => details.currentValue !== undefined && details.currentValue >= 0 ? colors.success : colors.error;

  const canCreate = () => {
    return (
      previewData?.sellOrders.some((order) => order.status !== SellOrderStatus.NOT_ENOUGH_BALANCE)
      && confirmations.every((confirmation) => confirmation)
    );
  };

  return (
    <Box width={"100%"}>
      <Card header={t("exitStrategy.title")} sx={{ mx: "auto", mb: 4 }}>
        {/* START OVERVIEW */}
        <Grid container rowSpacing={4} columnSpacing={5}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography fontSize={"1.25rem"} fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.overview")}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} md={12} lg={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ReviewCard
                      type="totalInvested"
                      color={theme.palette.tertiary.main}
                      amount={details.totalInvested}
                      scale={counterCurrDisplayedScale}
                      currency={counterCurrency}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReviewCard
                      type="currentValue"
                      color={getcurrentValueCardColor()}
                      amount={details.currentValue}
                      scale={counterCurrDisplayedScale}
                      currency={counterCurrency}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReviewCard
                      type="currentAmount"
                      color={theme.palette.primary.main}
                      amount={details.totalAmount}
                      scale={baseCurrDisplayedScale}
                      currency={baseCurrency}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={7} md={12} lg={7} sx={{ display: "flex", alignItems: "center" }}>
                <Grid container>
                  <Grid item xs={12} order={{ xs: 2, sm: 1, md: 2, lg: 1 }}>
                    <Chart
                      percentDifference={details.currentValuePercentDifference}
                      valueDifference={details.currentValueDifference}
                      currency={counterCurrency}
                      savingsTarget={savingsTarget}
                      totalInvested={details.totalInvested}
                      currentValue={details.currentValue}
                      counterCurrDisplayedScale={counterCurrDisplayedScale}
                    />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} order={{ xs: 1, sm: 2, md: 1, lg: 2 }} pt={2}>
                    <Typography variant="caption" fontWeight={400}>
                      <Trans
                        i18nKey="exitStrategy.create.setInitialAmount"
                        components={{
                          a: <Link href="#" onClick={() => setInitialValuesModalOpen(true)} />,
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" component="h2" fontWeight={400} sx={{ color: "tertiary.main" }}>
                  {t("exitStrategy.create.balanceInfo")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Balance
                  id={id}
                  exchangeTitle={exchangeConnection?.exchangeTitle ?? ""}
                  exchangeLabel={exchangeConnection?.label}
                  exchangeEnum={exchangeConnection?.exchangeEnum}
                  isBrokerage={exchangeConnection?.isBrokerage}
                  isExchangeEnabled={exchangeConnection?.isExchangeEnabled}
                  functional={exchangeConnection?.functional}
                  baseCurrency={baseCurrency}
                  counterCurrency={counterCurrency}
                  setIsBalanceError={setIsBalanceError}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* END OVERVIEW */}
        <Box py={6}>
          <Divider />
        </Box>
        <Box>
          <Stack spacing={3} pb={3}>
            <Typography fontWeight={600} fontSize={"1.5rem"}>
              {t("exitStrategy.create.introTitle")}
            </Typography>
            <Typography fontWeight={400} fontSize={"1rem"} color={colors["gray500"]}>
              <Trans
                i18nKey="exitStrategy.create.introDescription"
                values={{ baseCurrency }}
                components={{
                  aFees: <Link href="/proc-bitcoin/exit-objednavky-a-poplatky" target="_blank" />,
                }}
              />
            </Typography>
          </Stack>
        </Box>
        {/* START SELECT TYPE */}
        <Box>
          <Stack spacing={3} pb={3}>
            <Typography fontWeight={600} fontSize={"1.5rem"}>
              {t("exitStrategy.create.selectType")}
            </Typography>
            <Typography fontWeight={400} fontSize={"1rem"} color={colors["gray500"]}>
              {t("exitStrategy.create.typeDescription")}
            </Typography>
          </Stack>
          <TypeSelect
            options={enabledExitStrategies}
            value={formik.values.type}
            onChange={(type) => formik.setFieldValue("type", type)}
            disabled={isBalanceError || !exchangeConnection?.functional}
            baseCurrency={baseCurrency}
            counterCurrency={counterCurrency}
          />
        </Box>
        {/* END SELECT TYPE */}
        {formik.values.type && (
          <>
            <FormikProvider value={formik}>
              <Settings
                details={details}
                currencyPair={currencyPair}
                exchangeEnum={exchangeConnection.exchangeEnum}
                exchangeTitle={exchangeConnection.exchangeTitle}
                previewData={previewData}
                isBrokerage={exchangeConnection?.isBrokerage}
              />
              <BuyBack baseCurrency={baseCurrency} counterCurrency={counterCurrency} />
              <Preview
                data={sellOrders}
                selectedSellOrders={selectedSellOrders}
                setSelectedSellOrders={setSelectedSellOrders}
                exchangeTitle={exchangeConnection?.exchangeTitle ?? ""}
                currencyPair={currencyPair}
                exchangeEnum={exchangeConnection.exchangeEnum}
              />
            </FormikProvider>
            {selectedSellOrders.length > 0 && <Confirmation confirmations={confirmations} setConfirmations={setConfirmations} />}
            <Box display={"flex"} justifyContent={"center"} pt={6} pb={4}>
              <Button variant="contained" onClick={() => formik.handleSubmit()} disabled={!canCreate()}>
                {t("exitStrategy.create.create")}
              </Button>
            </Box>
          </>
        )}
      </Card>
      {initialValuesModalOpen && (
        <InitialValuesModal
          id={id}
          isOpen={initialValuesModalOpen}
          handleClose={() => setInitialValuesModalOpen(false)}
          exchangeEnum={exchangeConnection?.exchangeEnum}
          baseCurrency={baseCurrency}
          counterCurrency={counterCurrency}
          initialBaseCurrencyAmount={initialBaseCurrencyAmount}
          initialCounterCurrencyAmount={initialCounterCurrencyAmount}
          refreshData={refreshData}
          bidPrice={details.bidPrice}
        />
      )}
      <BackdropLoading open={isProcessing} />
    </Box>
  );
};

export default ExitStrategie;
