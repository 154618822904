export const CoinsHandLarge = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.4472 11.163C18.5313 11.8159 17.4105 12.2 16.2 12.2C13.1072 12.2 10.6 9.69279 10.6 6.6C10.6 3.50721 13.1072 1 16.2 1C18.2048 1 19.9635 2.05347 20.9528 3.63703M7.4 29.9394H11.5765C12.121 29.9394 12.6622 30.0042 13.1901 30.1338L17.603 31.2062C18.5606 31.4395 19.558 31.4622 20.5256 31.2742L25.4048 30.325C26.6937 30.0739 27.8793 29.4567 28.8085 28.5528L32.2606 25.1947C33.2465 24.2374 33.2465 22.6839 32.2606 21.7249C31.373 20.8615 29.9676 20.7643 28.9634 21.4965L24.9402 24.4318C24.364 24.8529 23.6629 25.0797 22.9418 25.0797H19.0568L21.5297 25.0796C22.9235 25.0796 24.0526 23.9813 24.0526 22.6255V22.1346C24.0526 21.0088 23.2649 20.0271 22.1425 19.755L18.3258 18.8268C17.7046 18.6761 17.0685 18.6 16.429 18.6C14.8853 18.6 12.091 19.8781 12.091 19.8781L7.4 21.8398M29.8 8.2C29.8 11.2928 27.2928 13.8 24.2 13.8C21.1072 13.8 18.6 11.2928 18.6 8.2C18.6 5.10721 21.1072 2.6 24.2 2.6C27.2928 2.6 29.8 5.10721 29.8 8.2ZM1 21.16L1 30.44C1 31.3361 1 31.7841 1.17439 32.1264C1.32779 32.4274 1.57256 32.6722 1.87361 32.8256C2.21587 33 2.66392 33 3.56 33H4.84C5.73608 33 6.18413 33 6.52638 32.8256C6.82744 32.6722 7.07221 32.4274 7.22561 32.1264C7.4 31.7841 7.4 31.3361 7.4 30.44V21.16C7.4 20.2639 7.4 19.8159 7.22561 19.4736C7.07221 19.1726 6.82744 18.9278 6.52639 18.7744C6.18413 18.6 5.73608 18.6 4.84 18.6L3.56 18.6C2.66392 18.6 2.21587 18.6 1.87362 18.7744C1.57256 18.9278 1.32779 19.1726 1.17439 19.4736C1 19.8159 1 20.2639 1 21.16Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
