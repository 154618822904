import { useState } from "react";

import Button from "@mui/material/Button";
import { dcaBotsDeleteExitStrategy } from "API/calls";
import Card from "components/elements/Card";
import useConfirm from "hooks/useConfirm";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IExitStrategy } from "types/types";

import Table from "./Table";

interface IProps {
  id: string;
  refetch: () => void;
  data: IExitStrategy;
  currencyPair: string;
  exchangeEnum: string;
  askPrice?: number;
  bidPrice?: number;
}

const ExitStrategy = ({ id, refetch, data, currencyPair, exchangeEnum, bidPrice, askPrice }: IProps) => {
  const { sellOrders, buyOrders } = data;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { confirm } = useConfirm();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const handleRemove = async () => {
    try {
      await confirm({
        title: t("dcaBots.exitStrategy.remove.areYouSure"),
        description: t("dcaBots.exitStrategy.remove.description"),
        dialogProps: { maxWidth: "xs" },
        confirmationText: t("dcaBots.exitStrategy.remove.confirm"),
        cancellationText: t("dcaBots.exitStrategy.remove.cancel"),
      });

      try {
        setIsProcessing(true);
        await dcaBotsDeleteExitStrategy(id);
        refetch();
        enqueueSnackbar(t("dcaBots.exitStrategy.remove.success"), { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(t("dcaBots.exitStrategy.remove.error"), { variant: "error" });
        setIsProcessing(false);
      }
    } catch (err: any) {
      // silent
    }
  };

  return (
    <Card
      sx={{ mb: 0 }}
      header={t("dcaBots.exitStrategy.title")}
      actions={
        <Button color="error" variant="outlined" onClick={handleRemove} size="small" disabled={isProcessing}>
          {t("dcaBots.exitStrategy.remove.button")}
        </Button>
      }>
      {sellOrders && sellOrders.length > 0 && (
        <Table data={sellOrders} currencyPair={currencyPair} exchangeEnum={exchangeEnum} type="sell" rate={bidPrice} />
      )}
      {buyOrders && buyOrders.length > 0 && (
        <Table data={buyOrders} currencyPair={currencyPair} exchangeEnum={exchangeEnum} type="buy" rate={askPrice} />
      )}
    </Card>
  );
};

export default ExitStrategy;
