import { LinearProgress, LinearProgressProps, linearProgressClasses, styled, Stack } from "@mui/material";
import MaskedModeWrapper from "components/elements/MaskedModeWrapper";
import SatsView from "components/modules/SatsView";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { percentage } from "utils";

interface ILinearDifferenceProps extends LinearProgressProps {
  type?: "error" | "success";
  currentValue: number;
  value: number;
  currency?: string;
  scale: number;
}

type IBorderLinearProgressProps = Omit<ILinearDifferenceProps, "currentValue" | "currency" | "scale">;

const BorderLinearProgress = styled(LinearProgress)<IBorderLinearProgressProps>(({ value, type }) => {
  const { colors } = useMUIThemeModeContext();
  const basicBackgroundColor = type === "error" ? colors.error : colors.success;

  return {
    height: 6,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: colors["purple500"],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRight: value && value < 100 ? `3px solid ${colors.gray100}` : undefined,
      backgroundColor: basicBackgroundColor,
    },
  };
});

const LinearDifference = ({ currentValue, value, type, currency, scale, ...props }: ILinearDifferenceProps) => {
  const { colors } = useMUIThemeModeContext();

  const getValue = () => {
    if (currentValue === 0) return 0;
    if (currentValue < value) return percentage(value, currentValue);

    return percentage(currentValue, value);
  };

  const getLabel = () => {
    const diff = value - currentValue;
    const leftSidePrefix = type === "error" ? "+" : "-";
    const rightSidePrefix = type === "error" ? "-" : "+";

    return (
      <Stack justifyContent={"space-between"} direction={"row"}>
        <MaskedModeWrapper color={colors["gray500"]} fontSize={"0.875rem"}>
          <SatsView
            color={colors["gray500"]}
            fontSize={"0.875rem"}
            value={currentValue}
            currency={currency ?? ""}
            scale={scale}
            prefix={leftSidePrefix}
          />
        </MaskedModeWrapper>
        {diff !== 0 && (
          <MaskedModeWrapper color={colors["gray500"]} fontSize={"0.875rem"}>
            <SatsView
              color={colors["gray500"]}
              fontSize={"0.875rem"}
              value={diff}
              currency={currency ?? ""}
              scale={scale}
              prefix={rightSidePrefix}
            />
          </MaskedModeWrapper>
        )}
      </Stack>
    );
  };

  return (
    <Stack spacing={1}>
      {type !== "error" && getLabel()}
      <BorderLinearProgress {...props} type={type} variant="determinate" value={getValue()} />
      {type === "error" && getLabel()}
    </Stack>
  );
};

export default LinearDifference;
