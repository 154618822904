import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Alert, AlertTitle, alpha, Box, Button, Collapse, Grid, Stack, Typography, useTheme } from "@mui/material";
import Badge from "components/elements/Badge";
import { badgeColors } from "components/elements/Badge/styles";
import { BadgeColors } from "components/elements/Badge/types";
import { ArrangeSquare2, CoinsHand, CoinsStacked, Diamond, Scales } from "components/icons";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import useExchangesMetadata from "hooks/useExchangesMetadata";
import { useTranslation } from "react-i18next";
import { SubscriptionType } from "types/enums";
import { IRunsSummary } from "types/types";
import { renderDate, renderNumber } from "utils/formatter";

import LinearDifference from "./LinearDifference";
import StatsCard from "./StatsCard";
import Tile from "./Tile";

interface ISummaryProps {
  data: IRunsSummary;
  exchangeEnum?: string;
  baseCurrency?: string;
  counterCurrency?: string;
  currentValue?: number;
  totalAmount?: number;
}

const Summary: React.FC<ISummaryProps> = ({
  data,
  baseCurrency,
  counterCurrency,
  exchangeEnum = "",
  totalAmount,
  currentValue,
}) => {
  const { firstOrderDate, lastOrderDate, totalOrdersCount, buyStats, sellStats, totalOrdersValueDifference } = data;
  const { subscriptionType } = useUserDataContext();
  const isPremium = subscriptionType === SubscriptionType.PREMIUM;
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { colors } = useMUIThemeModeContext();
  const { currencyPairPriceScale, baseCurrDisplayedScale, counterCurrDisplayedScale } = useExchangesMetadata(
    exchangeEnum,
    `${baseCurrency}/${counterCurrency}`
  );

  const getLegend = (key: string, color: BadgeColors, value?: number) => {
    const availableColors = badgeColors(theme);

    return (
      <Stack spacing={0.75} direction={"row"} alignItems={"center"}>
        <Box width={6} height={6} bgcolor={availableColors[color]} borderRadius="50%" />
        <Typography fontSize={"0.875rem"} color={colors["gray500"]}>
          {t(`dcaBots.runsList.summary.legend.${key}`)}
        </Typography>
        {value ? (
          <Badge color={color}>
            <Typography sx={{ py: 0.2 }} fontSize={"0.75rem"} fontWeight={700}>{`${renderNumber(value)}×`}</Typography>
          </Badge>
        ) : null}
      </Stack>
    );
  };

  const getLinearDifference = () => {
    const buyOrdersValue = buyStats?.ordersValue ?? 0;
    const sellOrdersValue = sellStats?.ordersValue ?? 0;

    return (
      <>
        <LinearDifference
          currentValue={buyOrdersValue}
          value={Math.max(buyOrdersValue, sellOrdersValue)}
          currency={counterCurrency}
          scale={counterCurrDisplayedScale}
        />
        <LinearDifference
          currentValue={sellOrdersValue}
          value={Math.max(buyOrdersValue, sellOrdersValue)}
          type="error"
          currency={counterCurrency}
          scale={counterCurrDisplayedScale}
        />
      </>
    );
  };

  return (
    <Stack pb={5} spacing={4}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Typography fontSize={{ xs: "1rem", sm: "1.25rem" }} fontWeight={600}>
          {t("dcaBots.runsList.summary.title")}
        </Typography>
        <Typography fontSize={{ xs: "0.75rem", sm: "0.875rem" }} color={"text.secondary"}>
          {renderDate(firstOrderDate)} - {renderDate(lastOrderDate)}
        </Typography>
      </Stack>
      <Box width={"100%"}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4} xl={3}>
            <Box bgcolor={alpha(colors["gray450"], 0.1)} p={3} borderRadius={"10px"}>
              <Stack spacing={1}>
                <Typography fontSize={"0.875rem"} color={colors["gray500"]}>
                  {t("dcaBots.runsList.summary.orders")}
                </Typography>
                <Typography fontSize={"1.5rem"} fontWeight={500}>
                  {renderNumber(totalOrdersCount)}×
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={7} md={8} xl={9}>
            <Stack justifyContent={{ xs: "center", md: "space-between" }} height={"100%"}>
              <Stack spacing={1} display={{ xs: "none", md: "block" }}>
                {getLinearDifference()}
              </Stack>
              <Stack spacing={{ xs: 1.5, xl: 2.5 }} direction={{ xs: "column", md: "row" }}>
                {getLegend("buy", "success", buyStats?.ordersCount ?? 0)}
                {getLegend("sell", "error", sellStats?.ordersCount ?? 0)}
                {getLegend("diff", "purple")}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} display={{ xs: "block", md: "none" }}>
            <Stack spacing={1}>{getLinearDifference()}</Stack>
          </Grid>
        </Grid>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {!isPremium && (
            <Alert
              style={{ backgroundColor: colors.purple100, color: colors.purple500, marginTop: "16px" }}
              icon={<Diamond style={{ color: colors.purple500 }} />}>
              <AlertTitle>{t("dcaBots.runsList.summary.upsellAlert.title")}</AlertTitle>
              {t("dcaBots.runsList.summary.upsellAlert.description")}
            </Alert>
          )}
          <Grid container spacing={1} my={3}>
            <Grid item xs={6} lg={3}>
              <Tile
                title={t("dcaBots.runsList.summary.currentAmount")}
                icon={<CoinsHand />}
                scale={baseCurrDisplayedScale}
                currency={baseCurrency}
                value={totalAmount}
              />
            </Grid>
            <Grid item xs={6} lg={3}>
              <Tile
                title={t("dcaBots.runsList.summary.currentValue")}
                icon={<CoinsStacked />}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                value={currentValue}
              />
            </Grid>
            <Grid item xs={6} lg={3.5}>
              <Tile
                title={t("dcaBots.runsList.summary.difference")}
                icon={<ArrangeSquare2 />}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                value={totalOrdersValueDifference}
                withPrefix
              />
            </Grid>
            <Grid item xs={6} lg={2.5}>
              <Tile
                title={t("dcaBots.runsList.summary.total")}
                icon={<Scales />}
                scale={counterCurrDisplayedScale}
                currency={counterCurrency}
                value={totalOrdersValueDifference + (currentValue || 0)}
                withPrefix
                tooltip={t("dcaBots.runsList.summary.totalTooltip")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            {buyStats && buyStats.ordersCount ? (
              <Grid item xs={12} md={sellStats && sellStats.ordersCount ? 6 : 12}>
                <StatsCard
                  type="buy"
                  stats={buyStats}
                  baseCurrency={baseCurrency}
                  baseScale={baseCurrDisplayedScale}
                  counterCurrency={counterCurrency}
                  counterScale={counterCurrDisplayedScale}
                  currencyPairPriceScale={currencyPairPriceScale}
                />
              </Grid>
            ) : null}
            {sellStats && sellStats.ordersCount ? (
              <Grid item xs={12} md={buyStats && buyStats.ordersCount ? 6 : 12}>
                <StatsCard
                  type="sell"
                  stats={sellStats}
                  baseCurrency={baseCurrency}
                  baseScale={baseCurrDisplayedScale}
                  counterCurrency={counterCurrency}
                  counterScale={counterCurrDisplayedScale}
                  currencyPairPriceScale={currencyPairPriceScale}
                />
              </Grid>
            ) : null}
          </Grid>
        </Collapse>
      </Box>
      <Box justifyContent={"center"} display={"flex"} onClick={() => setExpanded(!expanded)}>
        <Button variant={"text"} endIcon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
          {expanded ? t("dcaBots.runsList.summary.hideDetail") : t("dcaBots.runsList.summary.showDetail")}
        </Button>
      </Box>
    </Stack>
  );
};

export default Summary;
