import React from "react";

import { Diamond } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Grid, Slider, Stack, Typography } from "@mui/material";
import LogScale from "components/modules/DcaBotDetail/Balance/TradeModal/LimitBuy/LogScale";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import { useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { BuyBackModeType, SubscriptionType } from "types/enums";

import { ExitStrategyFormikValues } from "../module/useExitStrategyData";
import TypeSelect from "./TypeSelect";

const leftGridItemProps = {
  xs: 12,
  lg: 7,
};

const rightGridItemProps = {
  xs: 12,
  lg: 5,
};

const buyBackPriceStepPctMin = {
  [SubscriptionType.FREE]: 10,
  [SubscriptionType.PLUS]: 8,
  [SubscriptionType.PREMIUM]: 5,
};

const buyBackValuePctMax = {
  [SubscriptionType.FREE]: 100,
  [SubscriptionType.PLUS]: 100,
  [SubscriptionType.PREMIUM]: 200,
};

interface IProps {
  baseCurrency: string;
  counterCurrency: string;
}

const BuyBack: React.FC<IProps> = ({ baseCurrency, counterCurrency }) => {
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const { subscriptionType } = useUserDataContext();
  const formik = useFormikContext<ExitStrategyFormikValues>();

  return (
    <Box mt={11} p={{ xs: 2, md: 4, lg: 6 }} bgcolor={colors.lightGray} borderRadius={"16px"}>
      <Stack spacing={3}>
        <Typography fontWeight={600} fontFamily={"1.5rem"}>
          {t("exitStrategy.create.buyBack.title")}
        </Typography>
        <Typography fontFamily={"1rem"} fontWeight={400} color={colors["gray500"]}>
          <Trans i18nKey="exitStrategy.create.buyBack.subtitle" values={{ baseCurrency }} />
        </Typography>
      </Stack>
      <TypeSelect value={formik.values.buyBackMode} onChange={(type) => formik.setFieldValue("buyBackMode", type)} />
      {formik.values.buyBackMode !== BuyBackModeType.NONE && (
        <>
          <Grid container spacing={4} mt={4}>
            <Grid item {...leftGridItemProps}>
              <Stack spacing={2}>
                <Typography fontWeight={600} fontSize={"1rem"}>
                  {t("exitStrategy.create.buyBack.buyBackPriceStepPctTitle")}
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans i18nKey="exitStrategy.create.buyBack.buyBackPriceStepPctDescription" />
                </Typography>
              </Stack>
            </Grid>
            <Grid item {...rightGridItemProps}>
              <Box pt={4} px={3}>
                <LogScale
                  value={formik.values.buyBackPriceStepPct}
                  defaultValue={formik.values.buyBackPriceStepPct}
                  min={buyBackPriceStepPctMin[subscriptionType ?? SubscriptionType.FREE]}
                  step={1}
                  max={90}
                  valueLabelFormat={(n: number) => `-${n} %`}
                  onChange={(value) => formik.setFieldValue("buyBackPriceStepPct", value)}
                  valueLabelDisplay={"on"}
                  sx={{
                    color: "success.main",
                  }}
                />
              </Box>
            </Grid>
            {subscriptionType !== SubscriptionType.PREMIUM && (
              <Grid item xs={12}>
                <Alert
                  style={{ backgroundColor: colors.purple100, color: colors.purple500 }}
                  icon={<Diamond style={{ color: colors.purple500 }} />}>
                  <AlertTitle>
                    <Trans i18nKey="exitStrategy.create.buyBack.buyBackPriceStepPctUpsell.title" />
                  </AlertTitle>
                  <Trans i18nKey={`exitStrategy.create.buyBack.buyBackPriceStepPctUpsell.description.${subscriptionType}`} />
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={4} pt={6}>
            <Grid item {...leftGridItemProps}>
              <Stack spacing={2}>
                <Typography fontWeight={600} fontSize={"1rem"}>
                  {t("exitStrategy.create.buyBack.buyBackValuePctTitle")}
                </Typography>
                <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                  <Trans
                    i18nKey="exitStrategy.create.buyBack.buyBackValuePctDescription1"
                    values={{ baseCurrency, counterCurrency }}
                  />
                </Typography>
              </Stack>
            </Grid>
            <Grid item {...rightGridItemProps}>
              <Box pt={4} px={3}>
                <Slider
                  defaultValue={formik.values.buyBackValuePct}
                  min={1}
                  step={1}
                  max={buyBackValuePctMax[subscriptionType ?? SubscriptionType.FREE]}
                  valueLabelDisplay="on"
                  onChangeCommitted={(_e, value) => formik.setFieldValue("buyBackValuePct", value as number)}
                  valueLabelFormat={(n: number) => `${n} %`}
                  sx={{ color: "success.main" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={400} fontSize={"0.875rem"} color={colors["gray500"]}>
                <Trans
                  i18nKey="exitStrategy.create.buyBack.buyBackValuePctDescription2"
                  values={{ baseCurrency, counterCurrency }}
                />
              </Typography>
            </Grid>
            {subscriptionType !== SubscriptionType.PREMIUM && (
              <Grid item xs={12}>
                <Alert
                  style={{ backgroundColor: colors.purple100, color: colors.purple500 }}
                  icon={<Diamond style={{ color: colors.purple500 }} />}>
                  <AlertTitle>
                    <Trans i18nKey={`exitStrategy.create.buyBack.buyBackValuePctUpsell.title`} />
                  </AlertTitle>
                  <Trans i18nKey={`exitStrategy.create.buyBack.buyBackValuePctUpsell.description`} />
                </Alert>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default BuyBack;
