import React, { useEffect, useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getMaskedValue } from "components/elements/MaskedModeWrapper";
import { useMUIThemeModeContext } from "context/MUIThemeModeContext";
import { useUserDataContext } from "context/UserDataContext";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { renderNumber } from "utils/formatter";

import { MainWrapper } from "./styles";
import IProps from "./types";

const Chart: React.FC<IProps> = ({
  percentDifference,
  valueDifference,
  currency,
  savingsTarget,
  totalInvested,
  currentValue,
  width = "400",
  counterCurrDisplayedScale,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { colors } = useMUIThemeModeContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(330));
  
  const { isMasked } = useUserDataContext();
  const [innerIsMasked, setInnerIsMasked] = useState<boolean>(isMasked);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  useEffect(() => {
    setInnerIsMasked(isMasked);
  }, [isMasked]);

  const totalValue = `${valueDifference >= 0 ? "+" : "-"}${renderNumber(Math.abs(valueDifference), counterCurrDisplayedScale, true)} ${currency}`;

  const chartOptions = {
    plotOptions: {
      radialBar: {
        track: {
          background: theme.palette.mode === "light" ? "#F4F4F4" : "#202020",
        },
        offsetY: 0,
        startAngle: -90,
        endAngle: 90,
        hollow: {
          margin: 5,
          size: "50%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            offsetY: isSmallScreen ? 5 : 15,
            show: true,
            fontWeight: 600,
            color: "rgb(109, 109, 109)",
          },
          total: {
            show: true,
            fontWeight: 600,
            color: percentDifference >= 0 ? colors.success : colors.error,
            label: `${percentDifference >= 0 ? "+" : "-"}${renderNumber(Math.abs(percentDifference), 2, true)}%`,
            formatter: () => getMaskedValue(innerIsMasked, totalValue),
          },
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    colors: [
      theme.palette.tertiary.main,
      percentDifference >= 0 ? colors.success : colors.error,
    ],
    labels: [t("dcaBots.totalInvested"), t("dcaBots.currentValue")],
  };

  const getChartSeries = () => {
    const totalValue = savingsTarget
      ? Number(savingsTarget)
      : totalInvested > currentValue
      ? totalInvested
      : currentValue;

    const totalInvestPercent = (100 * totalInvested) / totalValue || 0;
    const currentValuePercent = (100 * currentValue) / totalValue || 0;
    return [totalInvestPercent, currentValuePercent];
  };

  return (
    <MainWrapper
      key={`${innerIsMasked}`}
      sx={{ position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <ReactApexChart
        style={{ pointerEvents: "none" }}
        options={chartOptions}
        series={getChartSeries()}
        type="radialBar"
        width={"100%"}
      />
      {(isHovered && isMasked || (!innerIsMasked && isMasked)) && (
        <IconButton
          sx={{ top: 15, right: 15, position: "absolute" }}
          size="small"
          onClick={() => setInnerIsMasked(!innerIsMasked)}>
          {innerIsMasked ? <VisibilityIcon fontSize={"small"} /> : <VisibilityOffIcon fontSize={"small"} />}
        </IconButton>
      )}
    </MainWrapper>
  );
};

export default Chart;
