import { SvgIcon } from "@mui/material";

export const Buy = (props: any) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21">
      <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.84212 2.44957C10.6223 1.70713 13.7116 2.42645 15.8926 4.60753C19.147 7.86189 19.147 13.1383 15.8926 16.3926C12.6383 19.647 7.36189 19.647 4.10753 16.3926C1.92645 14.2116 1.20713 11.1223 1.94957 8.34212M12.5002 8.00009V13.0001M12.5002 13.0001H7.5002M12.5002 13.0001L4.16671 4.66671"
          stroke="#079455"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
