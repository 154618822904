import tierHierarchy from "constants/tierHierarchy";

import { Theme } from "@mui/material";
import { t, exists } from "i18next";
import { BotStrategyType, OrderType, SubscriptionType } from "types/enums";
import { IErrorParameters } from "types/types";

import c from "../constants";

export const getOrderTypeByBotStrategyType = (botStrategyType: BotStrategyType | undefined): OrderType | undefined => {
  if (botStrategyType === BotStrategyType.MARKET) {
    return OrderType.MARKET;
  }
  if (botStrategyType === BotStrategyType.LIMIT) {
    return OrderType.LIMIT;
  }
  return undefined;
};

export const isAtTierOrHigher = (userTier: SubscriptionType, tier: SubscriptionType) => {
  return tierHierarchy.indexOf(userTier) >= tierHierarchy.indexOf(tier);
};

export const splitTranslatedBotFrequency = (
  frequency: string,
  frequencyParameter: number
): { every: string; day: string } => {
  const keywords = [
    t("dcaBots.frequency.every.4"),
    t("dcaBots.frequency.every.3"),
    t("dcaBots.frequency.every.2"),
    t("dcaBots.frequency.every.1"),
  ];

  const value = (translateBotFrequency(frequency, frequencyParameter) ?? "").toLocaleLowerCase();

  for (const keyword of keywords) {
    const index = value.indexOf(keyword);

    if (index !== -1) {
      return {
        every: value.slice(0, keyword.length).trim(),
        day: value.slice(keyword.length).trim(),
      };
    }
  }

  return { every: "", day: "" };
};

export const translateBotFrequency = (frequency: string, frequencyParameter: number) => {
  if (frequency === "M") return t("dcaBots.frequency.month", { day: frequencyParameter });
  if (frequency === "W")
    return t("dcaBots.frequency.week", { day: translateNumberToDay(frequencyParameter - 1, true) });
  if (frequency === "D") {
    if (frequencyParameter === 1) return t("dcaBots.frequency.day1", { day: frequencyParameter });
    else if (frequencyParameter > 1 && frequencyParameter < 5)
      return t("dcaBots.frequency.day2", { day: frequencyParameter });
    else return t("dcaBots.frequency.day3", { day: frequencyParameter });
  }
};

export const translateNumberToDay = (number: number, withOn?: boolean) => {
  const daysInWeek = [
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}monday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}tuesday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}wednesday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}thursday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}friday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}saturday`),
    t(`dcaBots.frequency.days.${withOn ? "on" : ""}sunday`),
  ];

  return daysInWeek[number];
};

export const splitCurrencyPair = (currencyPair: string) => {
  const splittedCurrencyPair = currencyPair?.split("/");

  return {
    baseCurrency: (Array.isArray(splittedCurrencyPair) && splittedCurrencyPair[0]) || "",
    counterCurrency: (Array.isArray(splittedCurrencyPair) && splittedCurrencyPair[1]) || "",
  };
};

export const isEmptyArray = (arr?: any) => {
  return Array.isArray(arr) && !arr.length;
};

export const getApiPath = (url: string): string => {
  // BEWARE! Following line is being text-replaced on Bitbucket during deployment!
  const SERVER_HOST = c.VPS_PROD;

  return `${SERVER_HOST}${url}`;
};

export const isProduction = (): boolean => {
  const currentDomain = getApiPath("");

  return currentDomain === c.VPS_PROD;
};

export const processErrorCode = (
  errorCode: string | null,
  parameters: IErrorParameters | null,
  defaultError: string | null = ""
) => {
  if (exists(`errorCode.${errorCode}`)) return t(`errorCode.${errorCode}`, { ...parameters });
  if (errorCode === "ERROR_CALCULATING" && parameters) {
    const { ORDER_VALUE, COUNTER_MIN_AMOUNT, ORDER_AMOUNT, MIN_AMOUNT, MAX_AMOUNT, BASE_CURRENCY, COUNTER_CURRENCY }
      = parameters;
    let errorMsg = "";

    if (!!ORDER_VALUE && !!COUNTER_MIN_AMOUNT) {
      errorMsg = t(`errorCode.ERROR_CALCULATING_BELOW_COUNTER_MIN_AMOUNT`, {
        ORDER_VALUE,
        COUNTER_MIN_AMOUNT,
        COUNTER_CURRENCY,
      });
    }
    if (!!ORDER_AMOUNT && !!MIN_AMOUNT) {
      errorMsg
        += (errorMsg.length > 0 ? " " : "")
        + t(`errorCode.ERROR_CALCULATING_BELOW_MIN_AMOUNT`, { ORDER_AMOUNT, MIN_AMOUNT, BASE_CURRENCY });
    }
    if (!!ORDER_AMOUNT && !!MAX_AMOUNT) {
      errorMsg
        += (errorMsg.length > 0 ? " " : "")
        + t(`errorCode.ERROR_CALCULATING_ABOVE_MAX_AMOUNT`, { ORDER_AMOUNT, MAX_AMOUNT, BASE_CURRENCY });
    }

    return errorMsg;
  }

  return defaultError;
};

interface IConditionalWrapper {
  condition: boolean;
  wrapper: (node: JSX.Element) => JSX.Element;
  children: JSX.Element;
}
export const ConditionalWrapper = ({ condition, wrapper, children }: IConditionalWrapper): JSX.Element =>
  condition ? wrapper(children) : children;

export const increaseByPercentage = (value: number, percentage: number) => {
  return value * (1 + percentage / 100);
};

export const decreaseByPercentage = (value: number, percentage: number) => {
  return value * (1 - percentage / 100);
};

export const percentageChange = (origin: number, current: number) => {
  return 100 * ((current - origin) / origin);
};

export const percentage = (origin: number, current: number) => {
  return Math.round((100 * current) / origin);
};

export const whyBitcoinRoute = (url: string) => `/proc-bitcoin${url}`;

export const isTouchDevice = () => {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.maxTouchPoints > 0;
};

export const iOS = () => {
  const platform = navigator.userAgentData?.platform || navigator.platform;

  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export const getBrowserLocale = (): string => {
  const supportedLanguages = ["en", "sk", "cs"];

  if (navigator.languages && navigator.languages.length > 0) {
    for (const lang of navigator.languages) {
      const locale = lang.toLowerCase();
      const language = locale.split('-')[0];

      if (supportedLanguages.includes(language)) {
        return locale;
      }
    }
  }

  return "en";
};

export const getColorByMode = (theme: Theme, main: string, dark: string) => {
  return theme.palette.mode === "light" ? main : dark;
};

export const getComponentByMode = (theme: Theme, main: React.ReactNode | null, dark: React.ReactNode | null) => {
  return theme.palette.mode === "light" ? main : dark;
};

export const getAnyByMode = (theme: Theme, main: any, dark: any) => {
  return theme.palette.mode === "light" ? main : dark;
};

export const hasAllValuesFalse = (obj: object) => Object.values(obj).every(item => !item);

export const sampleArray = (arr: any[], sampleSize: number) => {
  if (sampleSize >= arr.length) {
    return arr;
  }

  const result =  [arr[0]];
  const step = (arr.length - 2) / (sampleSize - 2);

  for (let i = 1; i < sampleSize - 1; i++) {
    const index = Math.round(i * step);
    result.push(arr[index]);
  }

  result.push(arr[arr.length - 1]);

  return result;
}

export const getLocalStorageBooleanValue = (key: string, defaultValue?: boolean) => {
  const value = localStorage.getItem(key);

  if (value === null && defaultValue) return defaultValue;
  return value === "true";
}